<template>
  <main>
    <div class="formHeadTitle">
      <h2>New Seller</h2>
      <p>
        <router-link to="/sellers">Sellers</router-link> <font-awesome-icon icon="fa-caret-right"></font-awesome-icon>
        New Seller
      </p>
    </div>
    <seller-form @submit-form="addSeller" :error="error"></seller-form>
  </main>
</template>

<script>
import SellerForm from '@/components/seller/SellerForm.vue';
import { useSellerStore } from '@/store/sellers/index.js';
import { useApiResponseStore } from '@/store/apiResponse';
import { useApiRequest } from '@/composables/useApiRequest';

export default {
  components: {
    SellerForm
  },
  data() {
    return {
      error: null
    };
  },
  created() {
    this.storeSeller = useSellerStore();
    this.apiRequest = useApiRequest();
    this.storeApiResponse = useApiResponseStore();
  },
  computed: {
    isLoading() {
      return this.apiRequest.isLoading.value;
    },
  },
  methods: {
    async addSeller(data) {
      this.apiRequest.clearError();
      try {
        await this.apiRequest.handleRequest(this.storeSeller.addSeller, data);
        this.storeApiResponse.setSuccessMsg('A new Seller is Added', '/sellers');
      } catch (err) {
        if (err.response.status === 422) {
          this.error = err.response.data.errors;
        }
        else {
          this.storeApiResponse.setError(err.message);
        }
      }
    },
  },
};
</script>
