<template>
  <teleport to="body">
    <div v-if="show" class="backdrop">
      <transition name="dialog">
        <dialog open v-if="show">
          <header v-if="title">
            <slot name="header">
              <h2>{{ title }}</h2><button @click="tryClose"><font-awesome-icon icon="fa-xmark" /></button>
            </slot>
          </header>
          <section>
            <slot></slot>
          </section>
          <menu>
            <slot name="actions">
              <base-button @click="tryClose" v-if="!isError">cancel</base-button>
              <base-button @click="$emit('confirm')" mode='primaryBtn'>{{ isError ? 'Close' : 'Confirm' }}</base-button>
            </slot>
          </menu>
        </dialog>
      </transition>
    </div>
  </teleport>
</template>

<script>
import { useApiResponseStore } from '@/store/apiResponse/index.js';

export default {
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: false,
    },
    isError: {
      type: Boolean,
      required: false
    },
  },

  emits: ['cancel', 'confirm'],
  data() {
    return {
      store: useApiResponseStore(),
    };
  },
  methods: {
    tryClose() {
      this.$emit('cancel');
    },
  },
};
</script>
<style src="@/assets/dialog.css" lang="css" scoped >
</style>
<style scoped>
button:hover {
  box-shadow: 0 3px 11px rgba(0, 0, 0, 0.23);
}

button {
  margin-right: 12px;
}

button:last-child {
  margin-right: 0px;
}

h2 {
  font-weight: normal;
  margin: 0;
  padding: 20px;
  color: var(--label-secondary-color)
}

header button {
  position: absolute;
  right: 15px;
  top: 18px;
  background: none;
  border: 0;
  font-size: 18px;
  color: var(--text-color);
  cursor: pointer;
}

menu {
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
  margin: 0;
}
</style>
