import axios from 'axios';
import { API_URL } from '@/utils/url';
import qs from 'qs';
import { useApiResponseStore } from '@/store/apiResponse/index.js';

export const apiClient = axios.create({
  withCredentials: true,
  baseURL: API_URL,
});

// Request Interceptor
apiClient.interceptors.request.use(
  (config) => {
    const csrfToken = localStorage.getItem('liquandumCSRFtoken');
    if (csrfToken) {
      config.headers['X-CSRF-TOKEN'] = csrfToken;
    }
    return config;
  },
  (error) => {
    // Handle request error
    return Promise.reject(error);
  }
);

apiClient.interceptors.response.use(
  (response) => response.data,
  // Handle success response after a POST, PUT, or DELETE request in future if we have response from api
  //  { const isPostSuccess =
  //     response.config.method === 'post' && response.status === 201;
  //   const isPutSuccess =
  //     response.config.method === 'put' && response.status === 200;
  //   const isDeleteSuccess =
  //     response.config.method === 'delete' && response.status === 204;

  //   if (isPostSuccess || isPutSuccess || isDeleteSuccess) {
  //    //console.log('Success response:', response);
  //     }
  (error) => {
    const apiResponseStore = useApiResponseStore();
    apiResponseStore.setError(error);
    return Promise.reject(error);
  }
);
export default {
  getResourceList(resource, paramsObj = {}) {
    const searchParams = qs.stringify(paramsObj, {
      arrayFormat: 'brackets',
      filter: (prefix, value) => value || undefined,
    });
    const reqURL = `${API_URL}/${resource}?${searchParams.toString()}`;
    return apiClient.get(reqURL);
  },
  getResource(resource, identifier) {
    const reqURL = `${API_URL}/${resource}/${encodeURIComponent(identifier)}`;
    return apiClient.get(reqURL);
  },
  addResource(resource, data) {
    const reqURL = `${API_URL}/${resource}`;
    return apiClient.post(reqURL, data);
  },
  updateResource(resource, identifier, data) {
    const reqURL = `${API_URL}/${resource}/${encodeURIComponent(identifier)}`;
    return apiClient.put(reqURL, data);
  },
  deleteResource(resource, identifier = '', data = '') {
    const reqURL = `${API_URL}/${resource}/${encodeURIComponent(identifier)}`;
    return apiClient.delete(reqURL, { data: data });
  },
};
