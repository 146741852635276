<template>
  <main class="greenBg">
    <section class="headSection">
      <div class="headTitle">
        <h2>Financiers</h2>
        <base-button to="/financiers/new" mode='whiteBtn' link>New Financier</base-button>
      </div>
    </section>

    <section>
      <base-card class="tableCard">
        <div v-if="isLoading">
          <base-spinner></base-spinner>
        </div>
        <table v-else-if="hasFinancier">
          <tr>
            <th v-for="col in tableCol" :key="col">{{ col }}</th>
          </tr>
          <financier-item v-for="financier in financiers" :key="financier.slug" :financier="financier"
            @delete-financier="deleteFinancier"></financier-item>
        </table>
        <div v-else>
          <p class="centerAlign">No financier Found</p>
        </div>
      </base-card>
    </section>
  </main>
  <base-dialog :show="showDeleteDialog && deleteSlug" title="Delete Financier" @cancel="closeDialog"
    @confirm="handleDelete">
    <p>Do you really want to Delete <b>Slug:{{ deleteSlug }}</b> ?</p>
  </base-dialog>
</template>

<script>
import { useFinancierStore } from '@/store/financiers/index.js';
import FinancierItem from '@/components/financier/FinancierItem.vue';
import { useApiRequest } from '@/composables/useApiRequest';
import { useApiResponseStore } from '@/store/apiResponse';

export default {
  components: {
    FinancierItem,
  },
  data() {
    return {
      tableCol: ['Slug', 'Name', 'IBAN', 'BIC', 'Address', 'Actions'],
      showDeleteDialog: false,
      deleteSlug: '',
    };
  },
  created() {
    this.apiRequest = useApiRequest();
    this.apiRequest.clearError();
    this.storeFinancier = useFinancierStore();
    this.storeApiResponse = useApiResponseStore();
    this.loadFinanciers();
  },
  computed: {
    isLoading() {
      return this.apiRequest.isLoading.value;
    },
    financiers() {
      return this.storeFinancier.getFinanciers;
    },
    hasFinancier() {
      return this.financiers && (this.financiers.length > 0);
    }
  },
  methods: {
    async loadFinanciers() {
      await this.apiRequest.handleRequest(this.storeFinancier.fetchFinanciers);
    },
    deleteFinancier(slug) {
      this.showDeleteDialog = true;
      this.deleteSlug = slug;
    },
    closeDialog() {
      this.showDeleteDialog = false;
    },
    async handleDelete() {
      this.apiRequest.clearError();
      this.showDeleteDialog = false;
      try {
        await this.apiRequest.handleRequest(this.storeFinancier.deleteFinancier, this.deleteSlug);
        this.storeApiResponse.setSuccessMsg(`Financier : ${this.deleteSlug} is deleted`);
        this.loadFinanciers();
      } catch (err) {
        this.storeApiResponse.setError(err.message);
      }
    }
  },
};
</script>
