<template>
  <div id="pagination">
    <p>Showing {{ currentItemCount.start }} - {{ currentItemCount.end }} of <b>{{ total }}</b> items</p>
    <ul class="pagination">
      <li class="pagination-item">
        <button type="button" @click="onClickFirstPage" :disabled="isInFirstPage" class="first">
          First
        </button>
      </li>
      <li class="pagination-item">
        <button type="button" @click="onClickPreviousPage" :disabled="isInFirstPage">
          Prev
        </button>
      </li>
      <li v-for="page in pages" class="pagination-item" :key="page.name">
        <button type="button" @click="onClickPage(page.name)" :disabled="page.isDisabled"
          :class="{ active: isPageActive(page.name) }">
          {{ page.name }}
        </button>
      </li>
      <li class="pagination-item">
        <button type="button" @click="onClickNextPage" :disabled="isInLastPage">
          Next
        </button>
      </li>
      <li class="pagination-item">
        <button type="button" @click="onClickLastPage" :disabled="isInLastPage" class="last">
          Last
        </button>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  props: {
    maxVisibleButtons: {
      type: Number,
      required: false,
      default: 3
    },
    total: {
      type: Number,
      required: true
    },
    perPage: {
      type: Number || String,
      required: true
    },
    currentPage: {
      type: Number,
      required: true
    },
  },
  computed: {
    totalPages() {
      //  if we have 0 items show 1 empty page
      return this.total < this.perPage ? 1 : Math.ceil(this.total / this.perPage);
    },
    startPage() {
      if (this.currentPage === 1) {
        return 1;
      }
      if (this.currentPage === this.totalPages) {
        const start = this.totalPages - (this.maxVisibleButtons - 1);

        if (start === 0) {
          return 1;
        } else {
          return start;
        }
      }
      return this.currentPage - 1;

    },
    endPage() {
      return Math.min(this.startPage + this.maxVisibleButtons - 1, this.totalPages);

    },
    pages() {
      const range = [];
      for (let i = this.startPage; i <= this.endPage; i += 1) {
        range.push({
          name: i,
          isDisabled: i === this.currentPage
        });
      }
      return range;
    },
    isInFirstPage() {
      return this.currentPage === 1;
    },
    isInLastPage() {
      return this.currentPage === this.totalPages;
    },
    currentItemCount() {
      const start = (this.currentPage - 1) * this.perPage + 1;
      const end = this.total >= this.perPage * this.currentPage ? this.perPage * this.currentPage : this.total;
      return { start, end };
    }
  },
  methods: {
    onClickFirstPage() {
      this.$emit('setPage', 1);
    },
    onClickPreviousPage() {
      this.$emit('setPage', this.currentPage - 1);
    },
    onClickPage(page) {
      this.$emit('setPage', page);
    },
    onClickNextPage() {
      this.$emit('setPage', this.currentPage + 1);
    },
    onClickLastPage() {
      this.$emit('setPage', this.totalPages);
    },
    isPageActive(page) {
      return this.currentPage === page;
    },
  }
};
</script>
<style scoped>
#pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

p {
  text-align: center;
  color: #999;
}

ul.pagination {
  display: flex;
  list-style: none;
  justify-content: center;
  padding-left: 0;
}

button {
  color: #58666e;
  background-color: #fff;
  border: 1px solid #dee5e7;
  padding: 4px 10px;
  margin-left: -1px;
}

button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

button.first:disabled,
button.last:disabled {
  display: none;
}

button.active {
  color: #fff;
  background-color: #58666e;
  border-color: #dee5e7;
  opacity: 1;
}

button:hover {
  background: var(--primary-blue);
  border: 1px solid var(--primary-blue);
  color: #fff;
}
</style>
