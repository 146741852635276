export function formatCentsToEuro(cents = '') {
  if (cents) {
    // Convert cents to Euro
    const euros = cents / 100;
    // Format the Euro amount with custom separators
    const formattedAmount = euros.toLocaleString('de-DE', {
      style: 'decimal',
      minimumFractionDigits: 2,
    });
    // Replace dot with space as thousands separator
    const replacedAmount = formattedAmount.replace(/\./g, ' ');
    // Move Euro symbol to the beginning
    const euroFormattedAmount = `€ ${replacedAmount}`;
    return euroFormattedAmount;
  }
  return;
}

export function convertMoneyStrToInteger(moneyStr, currency = '€') {
  if (moneyStr) {
    const strAmt = moneyStr.replace(new RegExp(`[${currency}\\s-]`, 'g'), ''); // removing currency and  '-'
    const commaIndex = strAmt.indexOf(',');

    if (commaIndex !== -1) {
      const euros = strAmt.substring(0, commaIndex);
      const cents = strAmt.substring(commaIndex + 1);

      if (!isNaN(euros) && !isNaN(cents)) {
        const totalCents =
          parseInt(euros) * 100 + parseInt(cents.padEnd(2, '0'));
        if (moneyStr.includes('-')) {
          return -totalCents;
        } else {
          return totalCents;
        }
      }
    } else {
      const cents = parseInt(strAmt) * 100;
      if (moneyStr.includes('-')) {
        return -cents;
      } else {
        return cents;
      }
    }
  }

  return;
}
