<template>
  <main>
    <div class="formHeadTitle">
      <h2>Seller Details</h2>
      <p>
        <router-link to="/sellers">Sellers</router-link> <font-awesome-icon icon="fa-caret-right"></font-awesome-icon>
        Seller Details
      </p>
    </div>
    <base-card v-if="isLoading">
      <base-spinner></base-spinner>
    </base-card>

    <base-card v-else-if="seller" class="detailCard">
      <div>
        <p><label>Slug:</label><span>{{ seller.slug }}</span> </p>
        <p><label>Name:</label> <span>{{ seller.name }}</span></p>
        <p><label>IBAN:</label><span>{{ seller.iban }}</span> </p>
        <p><label>BIC:</label><span>{{ seller.bic }}</span></p>
        <p><label>Address:</label><span>{{ seller.address }}</span></p>
        <p><label>Datev Creditor Id:</label><span>{{ seller.datev_creditor_id }}</span></p>
        <p><label>Datev Debtor Id:</label><span>{{ seller.datev_debtor_id }}</span> </p>
        <p><label>Company Slugs:</label><span>{{ seller.company_slugs?.join(' ') }}</span></p>
        <p><label title="Purchased Claim Types Component">Purchased Claim Types:</label><span>{{
          seller.purchased_type_component
        }}</span></p>
      </div>
      <div class="footer-btn">
        <base-button mode="danger" @click="deleteSeller">Delete</base-button>
        <base-button :to="sellerDetailLink" mode="primaryBtn" link>Update</base-button>
      </div>
    </base-card>
    <base-card v-else>
      <p class="centerAlign">No Seller Found</p>
    </base-card>
    <base-dialog :show="showDeleteDialog" title="Delete Seller" @cancel="closeDialog" @confirm="handleDelete">
      <p>Do you really want to Delete <b>Slug:{{ slug }}</b> ?</p>
    </base-dialog>
  </main>
</template>

<script>
import { useSellerStore } from '@/store/sellers/index.js';
import { useApiResponseStore } from '@/store/apiResponse';
import { useApiRequest } from '@/composables/useApiRequest';

export default {
  props: ['slug'],
  data() {
    return {
      showDeleteDialog: false,
      storeSeller: useSellerStore(),
    };
  },
  computed: {
    isLoading() {
      return this.apiRequest.isLoading.value;
    },
    seller() {
      return this.storeSeller.getSeller;
    },
    sellerDetailLink() {
      return `${this.slug}/edit`;
    },
  },
  created() {
    this.storeSeller = useSellerStore();
    this.apiRequest = useApiRequest();
    this.storeApiResponse = useApiResponseStore();
    this.loadSeller();
  },
  methods: {
    async loadSeller() {
      await this.apiRequest.handleRequest(this.storeSeller.fetchSeller, this.slug);
    },
    deleteSeller() {
      this.showDeleteDialog = true;
    },
    closeDialog() {
      this.showDeleteDialog = false;
    },
    async handleDelete() {
      this.apiRequest.clearError();
      this.showDeleteDialog = false;
      try {
        await this.apiRequest.handleRequest(this.storeSeller.deleteSeller, this.slug);
        this.storeApiResponse.setSuccessMsg(`Sellers : ${this.slug} is deleted`, '/sellers');
      } catch (err) {
        this.storeApiResponse.setError(err.message);
      }
    }
  },
};
</script>
<style scoped src="@/assets/detailView.css">
</style>
<style scoped>
.detailCard label {
  width: 175px;
}
</style>

