<template>
  <tr>
    <td><input type="checkbox" :value="colItem.slug" @click="submitCheck" :checked="selectAll" /></td>
    <td v-for="item in colItem" :key="item" :class="item === colItem.status ? colItem.status : ''">
      <a v-if="item == colItem.slug" :href="assetDetailLink" target="_blank">{{ colItem.slug }}</a>
      <template v-else>
        {{ item }}
      </template>
    </td>
  </tr>
</template>
<script>
import { formatCentsToEuro } from '@/utils/priceFormate';
import '@/assets/table.css';
import { APP_INSTANCE_URL_MAPPER } from '@/utils/url';

export default {
  props: [
    'asset', 'selectAll', 'appInstance'
  ],
  computed: {
    assetDetailLink() {
      return `${APP_INSTANCE_URL_MAPPER[this.appInstance]}/case_files/${this.asset.slug}`;
    },
    colItem() {
      const { status, slug, company_slug, source_slug, source_company_slug,
        phase, state, balance_amt, main_claim_amt, creditor_fee_amt, main_claim_interest_amt,
        dca_fee_amt, dca_fee_expense_amt, dca_cost_amt } = this.asset;
      return {
        status, slug, company_slug, source_slug, source_company_slug,
        phase, state, balance_amt: formatCentsToEuro(balance_amt), main_claim_amt: formatCentsToEuro(main_claim_amt), creditor_fee_amt: formatCentsToEuro(creditor_fee_amt), main_claim_interest_amt: formatCentsToEuro(main_claim_interest_amt),
        dca_fee_amt: formatCentsToEuro(dca_fee_amt), dca_fee_expense_amt: formatCentsToEuro(dca_fee_expense_amt), dca_cost_amt: formatCentsToEuro(dca_cost_amt)
      };
    },
  },
  methods: {
    submitCheck(e) {
      const param = {
        slug: this.colItem.slug,
        checked: e.target.checked
      };
      this.$emit('select-asset', param);
    }
  }
};
</script>
<style scoped>
td:first-child {
  position: sticky;
  left: 0px;
  padding-right: 5px;
}

td:nth-child(2) {
  position: sticky;
  left: 28px;
}

td:nth-child(2):after {
  border-left: none;
}

td:nth-child(3) {
  position: sticky;
  left: 80px;
}

td:nth-child(3):after {
  pointer-events: none;
  content: "";
  display: inline-block;
  height: 100%;
  position: absolute;
  top: 0px;
  width: 1px;
  right: -1px;
  border-left: 1px solid #ddd;
}

input[type="checkbox"] {
  width: 15px;
  margin: 0;
}
</style>
