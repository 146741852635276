<template>
  <response-message></response-message>
  <div>
    <side-bar @clicked="SideNavClick">
      <nav-item />
    </side-bar>
    <div class="mainContainer" :class="{ shrink: shrink }">
      <the-header></the-header>
      <router-view></router-view>
      <the-footer></the-footer>
    </div>
  </div>
</template>

<script>
import TheHeader from '@/components/layouts/TheHeader.vue';
import SideBar from '@/components/layouts/SideBar.vue';
import NavItem from '@/components/NavItem.vue';
import TheFooter from './components/layouts/TheFooter.vue';
import ResponseMessage from '@/components/response/ResponseMessage.vue';
import { useAuthStore } from '@/store/auth/index.js';
import '@/assets/font.css';
import '@/assets/main.css';

export default {
  components: {
    TheHeader,
    SideBar,
    NavItem,
    TheFooter,
    ResponseMessage
  },
  setup() {
    const authStore = useAuthStore();
    return {
      authStore,
    };
  },
  data() {
    return {
      shrink: true,
    };
  },
  created() {
    this.authStore.tryLogin();
    this.watchLocalStorage();
  },
  methods: {
    SideNavClick(value) {
      this.shrink = value;
    },
    watchLocalStorage() {
      window.onstorage = () => {
        const user = localStorage.getItem('liquandumUser');
        if (!user) {
          this.$router.go({ name: 'LOGIN' });
        }
      };
    }
  },
  beforeUnmount() {
    window.onstorage = null;
  },
};
</script>

