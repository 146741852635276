<template>
  <base-card class="assetContainer">
    <form @submit.prevent="submitForm">
      <base-input textarea v-model="slugsString" label="Add Assets"
        placeholder="Type Slugs separated by space / newline / comma" id="slugsString" rows="8"
        :error="validationError || error?.slugsString" />
      <div class="footerBtn"><base-button mode="primaryBtn">Submit</base-button></div>
    </form>
  </base-card>
</template>
<script>
import { useAssetStore } from '@/store/assets/index.js';
import { useApiResponseStore } from '@/store/apiResponse';
import { useApiRequest } from '@/composables/useApiRequest';
import { hasDuplicates } from '@/utils/validators/commonValidator';

export default {
  data() {
    return {
      slugsString: '',
      error: null,
      validationError: null
    };
  },
  props: ['slug'],
  created() {
    this.storeAsset = useAssetStore();
    this.apiRequest = useApiRequest();
    this.storeApiResponse = useApiResponseStore();
  },
  computed: {
    redirectPath() {
      return `/cohorts/${this.slug}`;
    },
    isLoading() {
      return this.apiRequest.isLoading.value;
    },
  },
  watch: {
    slugsString() {
      this.validationError = this.hasDuplicates(this.transformAssets());
    }
  },
  methods: {
    hasDuplicates,
    transformAssets() {
      const assets = this.slugsString.replace(/(|\n|\t|\r)\W+/g, ' ').trim().split(' ').filter(i => i);
      return assets;
    },

    submitForm() {
      let assets = this.transformAssets().map(slug => new Object({ slug }));
      const formData = {
        cohort_slug: this.slug,
        assets,
      };
      this.addAssets(formData);
    },
    async addAssets(formData) {
      this.apiRequest.clearError();
      try {
        await this.apiRequest.handleRequest(this.storeAsset.addAssets, formData);
        this.storeApiResponse.setSuccessMsg('Assets have been Added');
        location.reload();
      } catch (err) {
        const { status, data } = err.response;
        if (status === 422 && data.errors.assets) {
          this.error = { 'slugsString': ': Duplicates found!' };
          let msg = '';
          const assetsErrMsg = data.errors.assets;
          for (const key in assetsErrMsg) {
            msg += `<p><b>${key}</b> : ${assetsErrMsg[key].join(', ')}</p>`;
          }
          this.storeApiResponse.setErrorMsg(msg, 'Duplicate Assets Found!');
        }
        else {
          this.storeApiResponse.setError(err.message);
        }
      }
    },
  }
};
</script>
<style scoped>
.card {
  margin: 0 0 25px 0;
  padding: 30px;
}

.assetContainer:deep(label) {
  font-size: 16px;
  width: auto;
  height: 36px;
  color: var(--label-secondary-color);
}
</style>
