<template>
    <footer>
        <p>@copyright 2023 | Liquandum Capital</p>
    </footer>
</template>
<style scoped>
footer {
    text-align: center;
    padding-bottom: 0;
    padding-top: 6px;
    background: 0 0;
    position: absolute;
    left: 0;
    right: 0;
    color: #fff;
    bottom: 0;
    background: #64c5b1;
}
</style>
