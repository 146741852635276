<template>
  <main>
    <div class="formHeadTitle">
      <h2>Update Seller</h2>
      <p>
        <router-link to="/sellers">Sellers</router-link> <font-awesome-icon icon="fa-caret-right"></font-awesome-icon>
        Update Seller
      </p>
    </div>
    <base-card v-if="isLoading" class="loadingCard">
      <base-spinner></base-spinner>
    </base-card>
    <seller-form v-else-if="hasSeller" @submit-form="updateSeller" :seller="seller" :error="error"></seller-form>
    <base-card v-else>
      <p class="centerAlign">No Seller Found</p>
    </base-card>
  </main>
</template>

<script>
import { useSellerStore } from '@/store/sellers/index.js';
import SellerForm from '@/components/seller/SellerForm.vue';
import { useApiRequest } from '@/composables/useApiRequest';
import { useApiResponseStore } from '@/store/apiResponse';

export default {
  props: ['slug'],
  components: {
    SellerForm,
  },
  created() {
    this.storeSeller = useSellerStore();
    this.apiRequest = useApiRequest();
    this.storeApiResponse = useApiResponseStore();
    this.loadSeller();
  },
  data() {
    return {
      error: null
    };
  },
  computed: {
    isLoading() {
      return this.apiRequest.isLoading.value;
    },
    seller() {
      return this.storeSeller.getSeller;
    },
    hasSeller() {
      return !this.isLoading && this.seller;
    },
  },
  methods: {
    async loadSeller() {
      await this.apiRequest.handleRequest(this.storeSeller.fetchSeller, this.slug);
    },
    async updateSeller(data) {
      this.apiRequest.clearError();
      try {
        await this.apiRequest.handleRequest(this.storeSeller.updateSeller, data, this.slug);
        this.storeApiResponse.setSuccessMsg(`Seller : ${this.slug} is Updated`, '/sellers');
      } catch (err) {
        if (err.response.status === 422) {
          this.error = err.response.data.errors;
        }
        else {
          this.storeApiResponse.setError(err.message);
        }
      }
    },
  },
};
</script>
<style>
.loadingCard {
  padding: 25px;
}

.centerAlign {
  padding: 10px;
  text-align: center;
}
</style>

