<template>
  <base-dialog :title="title" isError :key=key :show="!!error" @cancel="clearError" @confirm="clearError">
    <p v-html="error"></p>
  </base-dialog>
  <base-info-dialog :show="!!successMsg" :redirectPath="redirectPath">
    <p>{{ successMsg }}</p>
  </base-info-dialog>
  <slot></slot>
</template>
<script setup>
import { useApiResponseStore } from '@/store/apiResponse/index.js';
import { storeToRefs } from 'pinia';
const store = useApiResponseStore();
const { title, error, successMsg, redirectPath, key } = storeToRefs(store);
const { clearError } = store;
</script>
