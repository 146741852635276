<template>
  <main>
    <div v-if="isLoading" class="loaderContainer">
      <base-spinner></base-spinner>
    </div>
    <template v-if="cohort">
      <section>
        <div class=" headTitle" :class="cohort.status">
          <h2>{{ cohort.name }}<span class="statusText">{{ cohort.status }}</span>
            <p class="breadcrumb">
              <router-link to="/cohorts">Cohorts</router-link> > <b>{{ slug }}</b>
            </p>
            <p class="breadcrumb">
              {{ cohort.description }}
            </p>
          </h2>
          <base-button mode='whiteBtn' @click="activeTab = 'addAssets'" :class="{ active: activeTab === 'addAssets' }">Add
            Assets</base-button>
        </div>
      </section>
      <section class="detailWrapper">
        <div>
          <cohort-summary :cohort="cohort"></cohort-summary>
        </div>

        <div>
          <div class="tabs">
            <button @click="activeTab = 'assets'" :class="{ active: activeTab === 'assets' }">Assets</button>
            <button @click="activeTab = 'reports'" :class="{ active: activeTab === 'reports' }">Reports</button>
            <button @click="activeTab = 'cohortInvoices'" :class="{ active: activeTab === 'cohortInvoices' }">
              Asset Invoices
            </button>
          </div>

          <div class="tab-content">
            <div v-show="activeTab === 'assets'">

              <base-card class="filterBaseCard">
                <filter-assets v-if="assetsFilter" :assets-filter='assetsFilter' :isDeleteAssets='isDeleteAssets'
                  @submit-filter="submitFilters" @delete-assets="deleteAssets"></filter-assets>
                <h3 v-else>No filters found</h3>
              </base-card>
              <template v-if="hasAssets">
                <base-card class="tableCard">
                  <table>
                    <tr>
                      <th><input type="checkbox" v-model="selectAllAssets" /></th>
                      <th v-for="col in tableCol" :key="col">{{ col }}</th>
                    </tr>
                    <cohort-asset v-for="asset in assets" :key="asset.slug" :asset="asset" :selectAll="selectAllAssets"
                      @select-asset="selectAsset" :appInstance="cohort.details.app_instance"></cohort-asset>
                  </table>
                </base-card>
                <pagination-item :total="totalResults" :per-page="perPage" :current-page="currentPage"
                  @setPage="onPageChange"></pagination-item>
              </template>
              <base-card v-else>
                <p class="centerAlign">No Assets Found</p>
              </base-card>
            </div>

            <div v-show="activeTab === 'reports'"><cohort-report :slug="slug"></cohort-report></div>
            <div v-show="activeTab === 'cohortInvoices'"><cohort-invoices :slug="slug"></cohort-invoices></div>
            <div v-show="activeTab === 'addAssets'"><add-assets :slug="slug"></add-assets>
            </div>
          </div>
        </div>
      </section>
    </template>
  </main>
</template>


<script>
import { useCohortStore } from '@/store/cohorts/index.js';
import { useAssetStore } from '@/store/assets/index.js';
import { useAssetsFilterStore } from '@/store/filters/assetFilter/index';
import { useApiResponseStore } from '@/store/apiResponse';
import { useApiRequest } from '@/composables/useApiRequest';
import CohortSummary from '@/components/cohort/CohortSummary.vue';
import CohortAsset from '@/components/cohort/CohortAsset.vue';
import PaginationItem from '@/components/pagination/PaginationItem.vue';
import FilterAssets from '@/components/filters/FilterAssets.vue';
import CohortReport from './CohortReport.vue';
import CohortInvoices from './CohortInvoices.vue';
import AddAssets from './AddAssets.vue';

import '@/assets/table.css';

export default {
  props: ['slug'],
  components: {
    CohortSummary,
    CohortAsset,
    CohortReport,
    CohortInvoices,
    PaginationItem,
    FilterAssets,
    AddAssets,
  },
  data() {
    return {
      activeTab: 'assets',
      currentPage: 1,
      perPage: 25, //initial value
      selectAllAssets: false,
      selectedAssets: [],
      filterData: null,
      tableCol: ['Status', 'Acquired Slug', 'Liquandum Creditor', 'Source Slug', 'Former Creditor', 'Phase', 'State', 'Balance', 'Main Claim', 'Creditor Fee', 'Main Claim Interest', 'DCA Fee', 'DCA Fee Expense', 'DCA Cost'],
    };
  },
  created() {
    this.storeCohort = useCohortStore();
    this.storeAsset = useAssetStore();
    this.storeAssetFilter = useAssetsFilterStore();
    this.apiRequest = useApiRequest();
    this.storeApiResponse = useApiResponseStore();
    this.loadCohortDetails();
  },

  watch: {
    selectAllAssets(newval) {
      if (newval) {
        this.selectedAssets = this.assets.map(item => item.slug);
      }
      else {
        this.selectedAssets = [];
      }
    },
  },
  computed: {
    isLoading() {
      return this.apiRequest.isLoading.value;
    },
    cohort() {
      return this.storeCohort.getCohort;
    },
    assets() {
      return this.storeAsset.getAssets;
    },
    hasAssets() {
      return this.assets?.length > 0;
    },
    totalResults() {
      return this.storeAsset.totalResults;
    },
    assetsFilter() {
      return this.storeAssetFilter.getAssetsFilters;
    },
    isDeleteAssets() {
      if (this.selectedAssets.length > 0) return true;
      else return false;
    },
  },
  methods: {
    async loadCohort() {
      await this.apiRequest.handleRequest(this.storeCohort.fetchCohort, this.slug);
    },
    async loadAssets() {
      const paramObj = {
        cohort_slug: this.slug,
        page: this.currentPage,
        ...this.filterData,
      };
      await this.apiRequest.handleRequest(this.storeAsset.fetchAssets, paramObj);
    },
    async loadAssetsFilter() {
      const paramObj = {
        assets: {
          cohort_slug: this.slug,
          columns: ['state', 'phase', 'status']
        }
      };
      await this.apiRequest.handleRequest(this.storeAssetFilter.fetchAssetsFilter, paramObj);
    },
    onPageChange(page) {
      this.currentPage = page;
      this.loadAssets();
    },
    submitFilters(filterData) {
      this.currentPage = 1;
      this.perPage = parseInt(filterData.per_page);
      this.filterData = filterData;
      this.loadAssets();
    },
    selectAsset(param) {
      if (param.checked) {
        this.selectedAssets.push(param.slug);
      }
      else {
        this.selectedAssets = this.selectedAssets.filter((asset) => asset !== param.slug);
      }
    },
    async deleteAssets() {
      const deleteAssetsData = {
        'cohort_slug': this.slug,
        'slugs': this.selectedAssets
      };
      this.apiRequest.clearError();
      try {
        await this.apiRequest.handleRequest(this.storeAsset.deleteAssets, deleteAssetsData);
        this.showDeleteDialog = false;
        this.storeApiResponse.setSuccessMsg('Selected assets are Deleted.');
        this.loadCohortDetails();
      } catch (err) {
        this.storeApiResponse.setError(err.message);
      }
    },
    loadCohortDetails() {
      this.loadCohort();
      this.loadAssets();
      this.loadAssetsFilter();
    }
  },
};
</script>
<style scoped>
.loaderContainer {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
  left: 0;
  right: 0;
  top: 0;
  padding: 50px;
}

section:nth-child(2),
.detailWrapper {
  display: flex;
  align-items: flex-start;
}

section>div {
  min-width: 315px;
}

section>div:nth-child(2) {
  display: flex;
  overflow: auto;
  margin-left: 30px;
  flex-direction: column;
  flex-grow: 1;
}

.headTitle {
  margin-bottom: 0;
}

.headTitle:before {
  left: 0;
  top: 60px;
  width: 100%;
  height: 200px;
  background: var(--green);
  content: '';
  position: absolute;
  z-index: -9;
}

.tabs {
  display: flex;
  list-style: none;
  padding: 0;
  margin-top: 1px;
  margin-bottom: 25px;
}

.tabs button {
  background: rgba(255, 255, 255, 0.5);
  padding: 7px 15px 6px 15px;
  margin: 0px 7px 0 0;
  border-radius: 5px 5px 0 0;
  border: 1px solid transparent;
  color: #666;
  font-size: 14px;
}

.tabs button.active {
  background: #f0f0f0;
  color: var(--secondary-heading-color);
}

.draft:before {
  background: var(--yellow);
}

.statusText {
  vertical-align: super;
  margin-left: 10px;
  font-size: 12px;
}

.filterBaseCard {
  margin-bottom: 20px;
}

.footer-btn {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
}

th:nth-child(2) {
  position: sticky;
  left: 28px;
}

th:nth-child(3) {
  position: sticky;
  left: 80px;
  border-right: 1px solid var(--gray);
}

th:nth-child(3):after,
td:nth-child(3):after {
  pointer-events: none;
  content: "";
  display: inline-block;
  height: 100%;
  position: absolute;
  top: 0px;
  width: 1px;
  right: -1px;
  border-left: 1px solid #ddd;
}

th:last-child {
  border-radius: 0 5px 0 0;
  position: relative;
}

input[type="checkbox"] {
  width: 15px;
  margin: 0;
}
</style>
