<template>
  <base-card>
    <h3>Cohort Details</h3>
    <div>
      <p><label>App Instance:</label><span> {{ cohort.details.app_instance }}</span></p>
      <p><label>Currency:</label><span> {{ cohort.details.currency }}</span></p>
      <p><label>Financier:</label><span>{{
        cohort.details.financier.name
      }}</span>
      </p>
      <p><label>Seller:</label><span> {{ cohort.details.seller.name }}-{{ cohort.details.seller.slug }}</span>
      </p>
      <p><label>Handover Date:</label><span> {{ cohort.details.handover_date }}</span> </p>
      <p><label>Purchase Date:</label><span> {{ cohort.details.purchase_date }}</span> </p>
      <p><label>Seller Invoice Number:</label><span> {{
        cohort.details.seller_invoice_number
      }}</span>
      </p>

    </div>
  </base-card>
  <base-card>
    <h3>Cohort Summary</h3>
    <div>
      <p><label>Claim Types:</label><span class="captilize">
          {{ cohort.summary.purchased_type_component.replaceAll('_', ' ') }}</span></p>
      <p><label>Total Assets:</label><span> {{ cohort.summary.total_asset_count }}</span></p>
      <p><label>Invoice Amount:</label><span> {{ invoice_amount }}</span></p>
      <p><label>Purchase Price:</label><span> {{ purchase_price }}</span></p>
      <p><label>Notional Price:</label><span> {{ notional_price }}</span></p>
      <p><label>Purchase Price Ratio:</label><span> {{ cohort.summary.purchase_price_ratio }}</span>
      </p>
    </div>
  </base-card>
  <base-card>
    <h3>Cohort Purchased Details</h3>
    <div>
      <p><label>Balance:</label><span> {{ balance_amt }}</span></p>
      <p><label>MainClaim:</label><span> {{ main_claim_amt }}</span></p>
      <p><label>CreditorFee:</label><span> {{ creditor_fee_amt }}</span></p>
      <p><label>MainClaimInterest:</label><span> {{ main_claim_interest_amt }}</span></p>
      <p><label>DcaFee:</label><span> {{ dca_fee_amt }}</span></p>
      <p><label>DcaFeeExpense:</label><span> {{ dca_fee_expense_amt }}</span></p>
      <p><label>DcaCost:</label><span> {{ dca_cost_amt }}</span></p>
    </div>
  </base-card>
</template>
<script>
import { formatCentsToEuro } from '@/utils/priceFormate';

export default {
  props: [
    'cohort',
  ],
  data() {
    return {
      invoice_amount: formatCentsToEuro(this.cohort.summary.invoice_amount),
      purchase_price: formatCentsToEuro(this.cohort.summary.purchase_price),
      notional_price: formatCentsToEuro(this.cohort.summary.notional_price),
      balance_amt: formatCentsToEuro(this.cohort.purchase_details.balance_amt),
      main_claim_amt: formatCentsToEuro(this.cohort.purchase_details.main_claim_amt),
      creditor_fee_amt: formatCentsToEuro(this.cohort.purchase_details.creditor_fee_amt),
      main_claim_interest_amt: formatCentsToEuro(this.cohort.purchase_details.main_claim_interest_amt),
      dca_fee_amt: formatCentsToEuro(this.cohort.purchase_details.dca_fee_amt),
      dca_fee_expense_amt: formatCentsToEuro(this.cohort.purchase_details.dca_fee_expense_amt),
      dca_cost_amt: formatCentsToEuro(this.cohort.purchase_details.dca_cost_amt),
    };
  }
};
</script>
<style scoped>
.cohortSummary {
  padding: 3px;
  color: var(--light-text-color);
}

h2 {
  font-size: 16px;
  color: var(--dark-text-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

h2>span {
  font-weight: normal;
  font-size: 14px;
}

span.slugName,
span.synced,
span.draft {
  padding: 6px 10px 3px 10px;
  border-radius: 12px;
  color: #fff;
  margin-left: 7px;
}

span.slugName {
  margin: 0 7px 0 0;
  color: var(--black);
  background: var(--primary-accent-color);
}

span.synced {
  background-color: var(--green);
}

span.draft {
  background-color: var(--yellow);
}

h3 {
  font-weight: normal;
  font-size: 18px;
  margin: 0 20px 20px 0;
  color: var(--secondary-heading-color);
}

.card {
  margin: 0 0 25px 0;
  padding: 22px;
}

label {
  width: 120px;
  color: var(--label-secondary-color);
  justify-content: flex-start;
  font-weight: normal;
  line-height: 1.2;
  height: auto;
  font-size: 13px;
  min-height: 26px;
}

.card p {
  color: var(--secondary-text-color);
  margin: 0;
  display: flex;
  justify-content: space-between;
}
</style>
