import { defineStore } from 'pinia';
import api from '@/services/api.js';

export const useCohortStore = defineStore({
  id: 'CohortStore',
  state: () => ({
    cohorts: [],
    currentCohort: null,
    totalCount: 0,
    appInstances: [],
    currencies: [],
  }),

  getters: {
    getCohorts: (state) => state.cohorts,
    getCohort: (state) => state.currentCohort,
    totalResults: (state) => state.totalCount,
  },
  actions: {
    async fetchCohorts(paramsObj) {
      const responseData = await api.getResourceList('cohorts', paramsObj);
      this.cohorts = responseData?.data || [];
      this.totalCount = responseData?.total_count || 0;
    },
    async fetchCohort(slug) {
      this.currentCohort = null;
      const responseData = await api.getResource('cohorts', slug);
      this.currentCohort = responseData?.cohort || null;
    },
    async addCohort(data) {
      await api.addResource('cohorts', data);
    },
    async updateCohort(data, prevSlug) {
      await api.updateResource('cohorts', prevSlug, data);
    },
    async deleteCohort(slug) {
      await api.deleteResource('cohorts', slug);
    },
    async fetchAppInstancesAndCurrencies() {
      const responseData = await api.getResourceList(
        'columns_values',
        { cohorts: {
          columns: ['app_instance', 'currency']
        } }
      );
      this.appInstances =
        responseData?.data?.cohorts?.app_instances || [];
      this.currencies =
        responseData?.data?.cohorts?.currencies || [];
    },
  },
});
