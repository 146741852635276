<template>
  <base-card>
    <form @submit.prevent="submitForm" class="form">
      <div>
        <div class="form-control disabled" v-if="financier">
          <label for="slug">Slug</label>
          <span>{{ slug }}</span>
        </div>
        <base-input v-else v-model="slug" label="Slug" type="text" id="slug" :error="error?.slug"
          :validate-fn="validateSlug" />
        <base-input v-model="name" label="Name" type="text" id="name" :error="error?.name" :validate-fn="validateName" />
      </div>
      <div>
        <base-input v-model="iban" label="IBAN" type="text" id="iban" :error="error?.iban"
          :validate-fn="validateAlphanumeric" />
        <base-input v-model="bic" label="BIC" type="text" id="bic" :error="error?.bic"
          :validate-fn="validateAlphanumeric" />
      </div>
      <div class="fullWidth">
        <base-input v-model="address" label="Address" type="text" id="address" :error="error?.address"
          :validate-fn="validateAddress" />
      </div>
      <div class="footerBtn"><base-button mode="primaryBtn" :disabled="isFormInvalid">Submit</base-button></div>
    </form>
  </base-card>
</template>

<script>
import { validateSlug, validateName, validateAddress } from '@/utils/validators/financierValidator';
import { validateAlphanumeric } from '@/utils/validators/commonValidator';

export default {
  props: ['financier', 'error'],
  data() {
    const { slug, name, iban, bic, address } = this.financier || {};
    return {
      slug: slug || '',
      name: name || '',
      iban: iban || '',
      bic: bic || '',
      address: address || '',
      validationErr: {},
    };
  },
  emits: ['submit-form'],
  computed: {
    isFormInvalid() {
      return validateSlug(this.slug) || validateName(this.name) || validateAddress(this.address) || validateAlphanumeric(this.bic) || validateAlphanumeric(this.iban);
    }
  },
  methods: {
    validateSlug, validateName, validateAddress, validateAlphanumeric,
    submitForm() {
      const formData = {
        slug: this.slug,
        name: this.name,
        iban: this.iban,
        bic: this.bic,
        address: this.address,
      };
      this.$emit('submit-form', formData);
    },

  },
};
</script>
<style scoped src="@/assets/form.css">
</style>
