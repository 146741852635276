<template>
  <div class="form-control" :class="{ invalid: error || validationError }">
    <label v-if="label" :for="id">{{ label }}</label>
    <textarea v-if="textarea" v-bind="$attrs" v-model="inputValue"
      @input="$emit('update:modelValue', $event.target.value)" :id="id" />
    <input v-else v-bind="$attrs" v-model="inputValue" @input="$emit('update:modelValue', $event.target.value)"
      :id="id" />
    <p v-if="error || validationError" class="errortxt">{{ error ? (label + ' ' + error) : validationError }}</p>
  </div>
</template>
<script>
export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
    error: {
      type: String,
      default: null
    },
    validateFn: {
      type: Function,
    },
    textarea: {
      type: Boolean,  //if input is textarea
      default: false
    },
    modelValue: {
      type: [String, Number],
      default: '',
    },
  },
  data() {
    return {
      inputValue: this.modelValue,
      validationError: false
    };
  },
  watch: {
    inputValue(newValue) {
      if (this.validateFn) {
        this.validationError = this.validateFn(newValue);
      }
    }
  },
};
</script>
<style scoped>
.form-control {
  display: flex;
  margin: 1rem 0;
  flex-direction: column;
}

label {
  display: inline-flex;
  align-items: flex-start;
  flex: 0 0 auto;
  height: 32px;
  line-height: 32px;
  padding: 0 20px 0 0;
  width: 125px;
  color: var(--label-secondary-color);
}

input[type="checkbox"]+label {
  font-weight: normal;
  display: inline;
  margin: 0 0 0 1rem;
}

input {
  display: flex;
  flex-wrap: wrap;
  line-height: 32px;
  position: relative;
  border: 1px solid var(--darkgray);
  border-radius: 3px;
  color: #666;
  padding-left: 7px;
  font-size: 14px;
}

input[type="date"] {
  display: inline-block;
}

input:focus {
  background-color: var(--active-bg);
  outline: none;
  border-color: var(--active-border);
}

input[type="checkbox"] {
  display: inline;
  width: auto;
  border: none;
}

input[type="checkbox"]:focus {
  outline: 1px solid var(--active-border);
}

p.errortxt {
  color: var(--red);
  margin: 3px 0 -12px 0;
}

.invalid input,
.invalid textarea {
  border: 1px solid var(--red);
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

::placeholder {
  color: #ababab;
  opacity: 1;
  /* Firefox */
}
</style>
