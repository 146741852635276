import { defineStore } from 'pinia';
import api from '@/services/api.js';

export const useReportStore = defineStore({
  id: 'ReportStore',
  state: () => ({
    reports: [],
    reportsCount: 0,
  }),

  getters: {
    getReports: (state) => state.reports,
    totalReportsResults: (state) => state.reportsCount,
  },
  actions: {
    async fetchReports(paramsObj) {
      const responseData = await api.getResourceList('reports', paramsObj);
      this.reports = responseData?.data || [];
      this.reportsCount = responseData?.total_count || 0;
    },
    async generateReports(data) {
      await api.addResource('reports', data);
    },
  },
});
