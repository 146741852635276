<template>
  <nav>
    <ul>
      <li>
        <router-link to="/cohorts">
          <font-awesome-icon icon="fa-table-cells-large" />
          Cohorts</router-link>
      </li>
      <li>
        <router-link to="/portfolios">
          <font-awesome-icon icon="fa-wallet" />
          Portfolios</router-link>
      </li>
      <li>
        <router-link to="/reports">
          <font-awesome-icon icon="fa-file-csv" />
          Reports</router-link>
      </li>
      <li>
        <router-link to="/financiers">
          <font-awesome-icon icon="fa-building-columns" />
          Financiers</router-link>
      </li>
      <li>
        <router-link to="/sellers">
          <font-awesome-icon icon="fa-money-bill" />
          Sellers</router-link>
      </li>
      <li>
        <router-link to="/failed_jobs">
          <font-awesome-icon icon="fa-hand" />
          Failed Jobs</router-link>
      </li>
    </ul>
  </nav>
</template>
<style scoped>
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

li svg {
  font-size: 16px;
  margin-right: 15px;
}

a {
  text-decoration: none;
  color: var(--dark-text-color);
  padding: 15px 25px;
  margin: 15px 0;
  display: block;
  font-size: 16px;
  border-left: 3px solid transparent;
  font-family: comfortaa, cursive;
}

a.router-link-active {
  color: var(--accent-green);
  transition: .3s;
}

a>svg {
  color: var(--green-fontIcon)
}
</style>
