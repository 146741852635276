<template>
  <tr>
    <td v-for="item in colItem" :key="item" :class="item === colItem.status ? colItem.status : ''">
      <a v-if="item == colItem.slug" :href="assetDetailLink" target="_blank">{{ colItem.slug }}</a>
      <template v-else>
        {{ item }}
      </template>
    </td>
  </tr>
</template>
<script>
import '@/assets/table.css';

export default {
  props: [
    'invoice'
  ],
  computed: {
    colItem() {
      const { asset_slug, source_id, created_at } = this.invoice;
      return {
        asset_slug, source_id, created_at
      };
    },
  },
};
</script>
<style scoped>
td:first-child {
  position: sticky;
  left: 0px;
  padding-right: 5px;
}

td:nth-child(2) {
  position: sticky;
  left: 28px;
}

td:nth-child(2):after {
  border-left: none;
}

td:nth-child(3) {
  position: sticky;
  left: 80px;
}

td:nth-child(3):after {
  pointer-events: none;
  content: "";
  display: inline-block;
  height: 100%;
  position: absolute;
  top: 0px;
  width: 1px;
  right: -1px;
  border-left: 1px solid #ddd;
}

input[type="checkbox"] {
  width: 15px;
  margin: 0;
}
</style>
