<template>
  <teleport to="body">
    <div v-if="show" class="backdrop">
      <transition name="dialog">
        <dialog open v-if="show">
          <section>
            <slot></slot>
          </section>
        </dialog>
      </transition>
    </div>
  </teleport>
</template>

<script>
import { useApiResponseStore } from '@/store/apiResponse/index.js';

export default {
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    redirectDelay: {
      type: Number,
      required: false,
      default: 1500
    },
    redirectPath: {
      type: String, //back-for navigating last page, or component path from router to redirect
      required: false,
    }
  },

  watch: {
    show(newVal) {
      if (newVal) {
        this.redirectPage();
      }
    },
  },
  data() {
    return {
      store: useApiResponseStore(),
    };
  },
  methods: {
    redirectPage() {
      setTimeout(() => {
        if (this.redirectPath) {
          if (this.redirectPath === 'back') {
            this.$router.back();
          }
          else {
            this.$router.replace(this.redirectPath);
          }
        }
        this.store.clearSuccessMsg();

      }, this.redirectDelay);
    },
  },
};
</script>
<style src="@/assets/dialog.css" lang="css" scoped >
</style>
