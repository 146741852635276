<template>
  <main>
    <div class="formHeadTitle">
      <h2>New Financier</h2>
      <p>
        <router-link to="/financiers">Financiers</router-link> <font-awesome-icon
          icon="fa-caret-right"></font-awesome-icon> New
        Financier
      </p>
    </div>
    <financier-form @submit-form="addFinancier" :error="error"></financier-form>
  </main>
</template>

<script>
import FinancierForm from '@/components/financier/FinancierForm.vue';
import { useFinancierStore } from '@/store/financiers/index.js';
import { useApiResponseStore } from '@/store/apiResponse';
import { useApiRequest } from '@/composables/useApiRequest';

export default {
  components: {
    FinancierForm,
  },
  data() {
    return {
      error: null
    };
  },
  created() {
    this.storeFinancier = useFinancierStore();
    this.apiRequest = useApiRequest();
    this.storeApiResponse = useApiResponseStore();
  },
  computed: {
    isLoading() {
      return this.apiRequest.isLoading.value;
    },
  },
  methods: {
    async addFinancier(data) {
      this.apiRequest.clearError();
      try {
        await this.apiRequest.handleRequest(this.storeFinancier.addFinancier, data);
        this.storeApiResponse.setSuccessMsg('A new Financier is Added', '/financiers');
      } catch (err) {
        if (err.response.status === 422) {
          this.error = err.response.data.errors;
        }
        else {
          this.storeApiResponse.setError(err.message);
        }
      }
    },
  },
};
</script>
