import { defineStore } from 'pinia';
import api from '@/services/api.js';

export const useSellerStore = defineStore({
  id: 'SellerStore',

  state: () => ({
    sellers: [],
    currentSeller: null,
    purchasedTypeComponents: [],
  }),

  getters: {
    getSellers: (state) => state.sellers,
    getSeller: (state) => state.currentSeller,
    getPurchasedTypeComponents: (state) => state.purchasedTypeComponents,
  },
  actions: {
    async fetchSellers() {
      const responseData = await api.getResourceList('sellers');
      this.sellers = responseData?.data || [];
    },
    async fetchSeller(slug) {
      this.currentSeller = null;
      const responseData = await api.getResource('sellers', slug);
      this.currentSeller = responseData?.seller || null;
    },
    async fetchPurchasedTypeComponents(paramsObj) {
      const responseData = await api.getResourceList(
        'columns_values',
        paramsObj
      );
      this.purchasedTypeComponents =
        responseData?.data?.sellers?.purchased_type_components || [];
    },
    async addSeller(data) {
      await api.addResource('sellers', data);
    },
    async updateSeller(data, prevSlug) {
      await api.updateResource('sellers', prevSlug, data);
    },
    async deleteSeller(slug) {
      await api.deleteResource('sellers', slug);
    },
  },
});
