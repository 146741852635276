<template>
  <base-card>
    <form @submit.prevent="submitForm" class="form">
      <div>
        <base-input v-model="name" label="Name" type="text" id="name" :error="error?.name"
          :validate-fn="validateCohortName" />
        <base-input v-model="description" label="Description" type="text" id="description" :error="error?.description"
          :validate-fn="validateCohortDescription" />
      </div>
      <div>
        <div :class="[{ disabled: isUpdateMode }, 'form-control']">
          <label for="financier_slug">Financier</label>
          <select v-if="isCreateMode && financiers" v-model="financier_slug" id="financier_slug">
            <option v-for="financier in financiers" :key="financier.slug" :value="financier.slug">{{ financier.slug }} -
              {{ financier.name }}
            </option>
          </select>
          <span v-else>{{ financier_slug }}</span>
        </div>
        <div :class="[{ disabled: isUpdateMode }, 'form-control']">
          <label for="seller_slug">Seller</label>
          <select v-if="isCreateMode && sellers" v-model="seller_slug" id="seller_slug">
            <option v-for="seller in sellers" :key="seller.slug" :value="seller.slug">{{ seller.slug }} -
              {{ seller.name }}
            </option>
          </select>
          <span v-else>{{ seller_slug }}</span>
        </div>
      </div>
      <div class="fullWidth">
        <div class="form-control disabled">
          <label for="purchased_type_component" title="Purchased Claim Type Components">Purchased Claim Type</label>
          <span>{{ purchased_type_component || 'Select Seller to view components'
          }}</span>
        </div>
      </div>
      <div>
        <base-input v-model="handover_date" label="Handover Date" type="date" id="handoverDate"
          :validate-fn="validateDate" />
        <base-input v-model="purchase_date" label="Purchase Date" type="date" id="purchaseDate"
          :validate-fn="validateDate" />
      </div>
      <div>
        <base-input v-model="seller_invoice_number" label="Seller Invoice Number" type="text" id="seller_invoice_number"
          :error="error?.seller_invoice_number" :validate-fn="validateIfEmpty" />
        <price-input v-model="invoice_amount" label="Invoice Amount" id="invoice_amount" :error="error?.invoice_amount"
          allowNegative></price-input>
      </div>
      <div>
        <price-input v-model="purchase_price" label="Purchase Price" id="purchasePrice"
          :error="error?.purchase_price"></price-input>
        <div :class="[{ disabled: isUpdateMode }, 'form-control-container']">
          <div>
            <label for="app_instance"> App Instance </label>
            <select v-if="isCreateMode" v-model="app_instance" id="app_instance">
              <option v-for="appInstance in appInstances" :key="appInstance" :value="appInstance">{{ "Pair App " +
              appInstance.toUpperCase() }}</option>
            </select>
            <span v-else>{{ "Pair App " + app_instance.toUpperCase() }}</span>
          </div>
          <div>
            <label for="currency"> Currency </label>
            <select v-if="isCreateMode" v-model="currency" id="currency">
              <option v-for="currency in currencies" :key="currency" :value="currency">{{ currency }}</option>
            </select>
            <span v-else>{{ currency }}</span>
          </div>
        </div>
      </div>
      <div class="fullWidth">
        <base-input v-if="isCreateMode" textarea v-model="slugsString" label="Assets"
          placeholder="Type Slugs separated by space / newline / comma" id="slugsString"
          :error="this.validationErr.assets || error?.slugsString" />
      </div>
      <div class="footerBtn"><base-button :disabled="isFormInvalid" mode="primaryBtn">Submit</base-button></div>
    </form>
  </base-card>
</template>

<script>
import { useFinancierStore } from '@/store/financiers/index.js';
import { useSellerStore } from '@/store/sellers/index.js';
import { useCohortStore } from '@/store/cohorts/index.js';
import PriceInput from '@/components/cohort/PriceInput.vue';
import { formatCentsToEuro, convertMoneyStrToInteger } from '@/utils/priceFormate.js';
import { validateCohortName, validateCohortDescription } from '@/utils/validators/cohortValidator';
import { validateAlphanumeric, validateIfEmpty, validateDate, hasDuplicates } from '@/utils/validators/commonValidator';

export default {
  props: ['cohort', 'mode', 'error'], //mode=create||update
  components: {
    PriceInput
  },
  data() {
    const { name, description, details, summary } = this.cohort || {};
    return {
      isUpdateMode: this.mode === 'update',
      isCreateMode: this.mode === 'create',
      name: name || '',
      description: description || '',
      financier_slug: details?.financier?.slug || '',
      seller_slug: details?.seller?.slug || '',
      handover_date: details?.handover_date || '',
      purchase_date: details?.purchase_date || '',
      seller_invoice_number: details?.seller_invoice_number || '',
      invoice_amount: formatCentsToEuro(summary?.invoice_amount),
      purchase_price: formatCentsToEuro(summary?.purchase_price),
      app_instance: details?.app_instance || '',
      slugsString: '',
      currency: details?.currency || '',
      validationErr: {},
    };
  },
  emits: ['submit-form'],
  created() {
    this.storeFinancier = useFinancierStore();
    this.storeSeller = useSellerStore();
    this.storeCohort = useCohortStore();
    if (this.isCreateMode) {
      this.loadFinanciers();
      this.loadSellers();
      this.loadAppInstancesAndCurrencies();
    }
  },
  computed: {
    financiers() {
      return this.storeFinancier.getFinanciers;
    },
    sellers() {
      return this.storeSeller.getSellers;
    },
    purchased_type_component() {
      if (this.isCreateMode) {
        return this.sellers.find(seller => seller.slug === this.seller_slug)?.purchased_type_component;
      }
      else {
        return this.cohort.summary.purchased_type_component;
      }
    },
    currencies() {
      return this.storeCohort.currencies;
    },
    isFormInvalid() {
      return validateCohortName(this.name) || validateCohortDescription(this.description) || validateIfEmpty(this.seller_invoice_number) || validateIfEmpty(this.financier_slug) || validateIfEmpty(this.seller_slug) || validateIfEmpty(this.handover_date) || validateIfEmpty(this.purchase_date) || validateIfEmpty(this.invoice_amount) || validateIfEmpty(this.purchase_price) || validateIfEmpty(this.app_instance) || validateIfEmpty(this.currency);
    },
    appInstances() {
      return this.storeCohort.appInstances;
    },
  },
  watch: {
    slugsString() {
      this.validationErr.assets = this.hasDuplicates(this.transformAssets());
    }
  },
  methods: {
    validateDate, validateCohortName, validateCohortDescription, validateAlphanumeric, validateIfEmpty, hasDuplicates,
    async loadFinanciers() {
      await this.storeFinancier.fetchFinanciers();
    },
    async loadSellers() {
      await this.storeSeller.fetchSellers();
    },
    async loadAppInstancesAndCurrencies() {
      await this.storeCohort.fetchAppInstancesAndCurrencies();
    },
    transformAssets() {
      const assets = this.slugsString.replace(/(|\n|\t|\r)\W+/g, ' ').trim().split(' ').filter(i => i);
      return assets;
    },
    submitForm() {
      const formData = {
        name: this.name,
        description: this.description,
        handover_date: this.handover_date,
        purchase_date: this.purchase_date,
        seller_invoice_number: this.seller_invoice_number,
        invoice_amount: convertMoneyStrToInteger(this.invoice_amount),
        purchase_price: convertMoneyStrToInteger(this.purchase_price),
      };
      if (this.isCreateMode) {
        formData.financier_slug = this.financier_slug;
        formData.seller_slug = this.seller_slug;
        formData.app_instance = this.app_instance;
        formData.currency = this.currency;
        const assets = this.transformAssets().map(slug => new Object({ slug }));
        formData.assets = assets;
      }
      this.$emit('submit-form', formData);
    },
  }
};
</script>
<style scoped src="@/assets/form.css">
</style>
