import { defineStore } from 'pinia';
import api from '@/services/api.js';

export const useCohortImportStore = defineStore({
  id: 'CohortImportStore',
  actions: {
    async addCohortsImport(data) {
      await api.addResource('cohort_import_files', data);
    },
  },
});
