<template>
  <main>
    <div class="formHeadTitle">
      <h2>Update Financier</h2>
      <p>
        <router-link to="/financiers">Financiers</router-link> <font-awesome-icon
          icon="fa-caret-right"></font-awesome-icon>
        Update Financier
      </p>
    </div>
    <base-card v-if="isLoading" class="loadingCard">
      <base-spinner></base-spinner>
    </base-card>
    <financier-form v-else-if="financier" @submit-form="updateFinancier" :financier="financier"
      :error="error"></financier-form>
    <base-card v-else>
      <p class="centerAlign">No financier Found</p>
    </base-card>
  </main>
</template>

<script>
import { useFinancierStore } from '@/store/financiers/index.js';
import FinancierForm from '@/components/financier/FinancierForm.vue';
import { useApiRequest } from '@/composables/useApiRequest';
import { useApiResponseStore } from '@/store/apiResponse';

export default {
  props: ['slug'],
  components: {
    FinancierForm,
  },
  created() {
    this.storeFinancier = useFinancierStore();
    this.apiRequest = useApiRequest();
    this.storeApiResponse = useApiResponseStore();
    this.loadFinancier();
  },
  data() {
    return {
      error: null
    };
  },
  computed: {
    isLoading() {
      return this.apiRequest.isLoading.value;
    },
    financier() {
      return this.storeFinancier.getFinancier;
    },
  },
  methods: {
    async loadFinancier() {
      await this.apiRequest.handleRequest(this.storeFinancier.fetchFinancier, this.slug);
    },
    async updateFinancier(data) {
      this.apiRequest.clearError();
      try {
        await this.apiRequest.handleRequest(this.storeFinancier.updateFinancier, data, this.slug);
        this.storeApiResponse.setSuccessMsg(`Financier : ${this.slug} is Updated`, '/financiers');
      } catch (err) {
        if (err.response.status === 422) {
          this.error = err.response.data.errors;
        }
        else {
          this.storeApiResponse.setError(err.message);
        }
      }
    },
  },
};
</script>
<style>
.loadingCard {
  padding: 25px;
}

.centerAlign {
  padding: 10px;
  text-align: center;
}
</style>

