<template>
  <tr>
    <td>
      <router-link :to="sellerDetailLink">{{ seller.slug }}</router-link>
    </td>
    <td v-for="item in colItem" :key="item">
      {{ item }}
    </td>
    <td class="actionBtn"><base-button :to="sellerDetailLink" mode="primaryBtn iconBtn" link><font-awesome-icon
          icon="fa-eye" /></base-button><base-button :to="sellerUpdateLink" mode="primaryBtn iconBtn"
        link><font-awesome-icon icon="fa-edit" /></base-button><base-button @click="deleteSeller"
        mode="primaryBtn iconBtn"><font-awesome-icon icon="fa-trash" /></base-button>
    </td>
  </tr>
</template>
<script>
import '@/assets/table.css';
export default {
  props: [
    'seller',
  ],
  computed: {
    sellerDetailLink() {
      return `${this.$route.path}/${this.seller.slug}`;
    },
    sellerUpdateLink() {
      return `${this.$route.path}/${this.seller.slug}/edit`;
    },
    colItem() {
      const company_slugs = this.seller.company_slugs.toString();
      const { name, iban, bic, address, datev_creditor_id, datev_debtor_id, purchased_type_component } = this.seller;
      return { name, iban, bic, address, datev_creditor_id, datev_debtor_id, company_slugs, purchased_type_component };
    },
  },
  methods: {
    deleteSeller() {
      this.$emit('delete-seller', this.seller.slug);
    }
  }
};
</script>
