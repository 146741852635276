<template>
  <div class="form-control" :class="{ invalid: error || validationError }">
    <label v-if="label" :for="id">{{ label }}</label>
    <input v-bind="$attrs" v-model="formattedValue" type="text" placeholder=" 00,00" :id="id"
      @input="$emit('update:modelValue', formattedValue)" />
    <p v-if="error || validationError" class="errortxt">{{ error ? (label + ' ' + error) : validationError }}</p>
  </div>
</template>
<script>
export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
    currency: {
      type: String,
      default: '€'
    },
    modelValue: {
      type: [String, Number],
      default: '',
    },
    maxValue: {
      default: 999999999.99,
    },
    error: {
      type: String,
      default: null
    },
    allowNegative: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      formattedValue: this.modelValue,
      validationError: null
    };
  },
  watch: {
    formattedValue: function (newVal) {
      newVal = newVal.replace(/[^-0-9,]/g, '');
      this.formattedValue = this.formatAsEuro(newVal);
      this.validationError = this.validateFn(this.formattedValue);
    }
  },
  methods: {

    formatAsEuro: function (value) {
      let formattedValue = '';
      const parts = value.split(',');
      let integerPart = parts[0] || '0';
      let decimalPart = parts[1] || '';

      let isNegative = false;
      if (integerPart.startsWith('-')) {
        isNegative = true;
        integerPart = integerPart.slice(1);
      }
      for (let i = 0; i < integerPart.length; i++) {
        if (integerPart[integerPart.length - 1 - i] == '-') {
          continue;
        }
        if (i > 0 && i % 3 === 0) {
          formattedValue = ' ' + formattedValue;
        }
        formattedValue = integerPart[integerPart.length - 1 - i] + formattedValue;
      }

      if (isNegative && this.allowNegative) {
        // Add minus sign back at the start
        formattedValue = '-' + formattedValue;
      }

      if (formattedValue === '0') {
        return formattedValue = '';
      }

      if (decimalPart?.length > 0) {
        // Limit the decimal part to two digits
        decimalPart = decimalPart.slice(0, 2);
        formattedValue = formattedValue + ',' + decimalPart;
      }
      else if (value.indexOf(',') > 0) {
        formattedValue = formattedValue + ',';
      }
      return this.currency + ' ' + formattedValue;
    },

    validateFn(value) {
      let re = new RegExp('[' + this.currency + '\\s]', 'g');
      value = value.replace(re, '');
      if (value > this.maxValue) {
        return 'Accepts only 9 digit Integer';
      }
      else return;
    }
  }
};
</script>
<style>
p.errortxt {
  color: var(--red);
  margin: 3px 0 -12px 0;
}

.invalid input,
.invalid textarea {
  border: 1px solid var(--red);
}
</style>
