<template>
  <tr>
    <td v-for="item in colItem" :key="item" :class="item === colItem.status ? colItem.status : ''">
      <router-link v-if="item == colItem.slug" :to="cohortDetailLink">{{ cohort.slug }}</router-link>
      <template v-else>
        {{ item }}
      </template>
    </td>
    <td class="actionBtn"><base-button :to="cohortDetailLink" mode="primaryBtn iconBtn" link><font-awesome-icon
          icon="fa-eye" /></base-button><base-button :to="cohortUpdateLink" mode="primaryBtn iconBtn"
        link><font-awesome-icon icon="fa-edit" /></base-button><base-button @click="deleteCohort"
        mode="primaryBtn iconBtn"><font-awesome-icon icon="fa-trash" /></base-button>
    </td>
  </tr>
</template>
<script>
import moment from 'moment';
import '@/assets/table.css';
import { formatCentsToEuro } from '@/utils/priceFormate';


export default {
  props: [
    'cohort',
  ],
  computed: {
    cohortDetailLink() {
      return `${this.$route.path}/${this.cohort.slug}`;
    },
    cohortUpdateLink() {
      return `${this.$route.path}/${this.cohort.slug}/edit`;
    },
    colItem() {
      const item = {
        status: this.cohort.status,
        slug: this.cohort.slug,
        name: this.cohort.name,
        app_instance: this.cohort.details.app_instance,
        currency: this.cohort.details.currency,
        financier: this.cohort.details.financier.slug,
        seller: this.cohort.details.seller.slug,
        created_at: this.getDate(this.cohort.created_at),
        handover_date: this.cohort.details.handover_date,
        purchase_date: this.cohort.details.purchase_date,
        seller_invoice_number: this.cohort.details.seller_invoice_number,
        invoice_amount: formatCentsToEuro(this.cohort.summary.invoice_amount),
        purchased_type_component: this.cohort.summary.purchased_type_component,
        total_asset_count: this.cohort.summary.total_asset_count,
        purchase_price: formatCentsToEuro(this.cohort.summary.purchase_price),
        notional_price: formatCentsToEuro(this.cohort.summary.notional_price),
        purchase_price_ratio: this.cohort.summary.purchase_price_ratio
      };
      return item;
    },
  },
  methods: {
    deleteCohort() {
      this.$emit('delete-cohort', this.cohort.slug);
    },
    getDate(timestamp) {
      return moment(timestamp).format('YYYY-MM-DD');
    }
  }
};
</script>
