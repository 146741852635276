import { defineStore } from 'pinia';
import api from '@/services/api.js';

export const useAssetStore = defineStore({
  id: 'AssetStore',
  state: () => ({
    assets: [],
    totalCount: 0,
  }),

  getters: {
    getAssets: (state) => state.assets,
    totalResults: (state) => state.totalCount,
  },
  actions: {
    async fetchAssets(paramsObj) {
      const responseData = await api.getResourceList('assets', paramsObj);
      this.assets = responseData?.data || [];
      this.totalCount = responseData?.total_count || 0;
    },
    async addAssets(data) {
      await api.addResource('assets/bulk_create', data);
    },
    async deleteAssets(data) {
      await api.deleteResource('assets', 'bulk_destroy', data);
    },
  },
});
