<template>
  <main class="greenBg">
    <div class="headTitle">
      <h2>Failed Jobs</h2>
    </div>
    <div v-if="isLoading">
      <base-spinner></base-spinner>
    </div>
    <table v-else-if="hasFailedJob">
      <tr>
        <th v-for="col in tableCol" :key="col">{{ col }}</th>
      </tr>
      <failed-job-item v-for="failedJob in failedJobs" :key="failedJob.asset_slugs"
        :failedJob="failedJob"></failed-job-item>
    </table>
    <base-card v-else>
      <p class="centerAlign">No failed Job Found</p>
    </base-card>
  </main>
</template>

<script>
import { useFailedJobsStore } from '@/store/failed_jobs/index.js';
import { useApiRequest } from '@/composables/useApiRequest';
import FailedJobItem from '@/components/failed_job/FailedJobItem.vue';

export default {
  components: {
    FailedJobItem,
  },
  data() {
    return {
      tableCol: ['Cohort Name', 'Asset Slugs', 'Time']
    };
  },
  created() {
    this.storeFailedJob = useFailedJobsStore();
    this.apiRequest = useApiRequest();
    this.loadFailedJobs();
  },
  computed: {
    isLoading() {
      return this.apiRequest.isLoading.value;
    },
    failedJobs() {
      return this.storeFailedJob.getFailedJobs;
    },
    hasFailedJob() {
      return this.failedJobs?.length > 0;
    }
  },
  methods: {
    async loadFailedJobs() {
      await this.apiRequest.handleRequest(this.storeFailedJob.fetchFailedJobs);
    },
  },
};
</script>
<style scoped>
main {
  margin: 25px;
}

.headSection {
  margin-bottom: 20px;
}

.headTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.centerAlign {
  padding: 10px;
  text-align: center;
}

th {
  background: var(--gray);
  padding: 10px 12px;
  font-weight: normal;
  font-size: 16px;
  color: #000;
}

th:first-child {
  border-radius: 5px 0 0 0;
  position: sticky;
  left: 0px;
}

th:nth-child(2) {
  position: sticky;
  left: 67px;
  border-right: 1px solid var(--gray);
}

th:nth-child(2):after {
  pointer-events: none;
  content: "";
  display: inline-block;
  height: 100%;
  position: absolute;
  top: 0px;
  width: 1px;
  right: -1px;
  border-left: 1px solid #ddd;
}

th:last-child {
  border-radius: 0 5px 0 0;
}

table {
  text-align: left;
  border-collapse: collapse;
  width: 100%;
}

.card {
  overflow: auto;
}
</style>
