<template>
  <div :id="id" :class="showList ? 'active multiSelect' : 'multiSelect'">
    <label v-if="label" :for="id">{{ label }}</label>
    <ul class="selectedItems">
      <li v-for="item in selectedItems" :key="item"><input type="checkbox" checked v-model='selectedItems' :id="item"
          @change="$emit('update:modelValue', selectedItems)" :value="item"><label :for="item">{{ item
          }}<font-awesome-icon icon="fa-xmark" /></label>
      </li>
      <li class="searchList" @click="showList = !showList"><input type="search" v-model="searchQuery"></li>
    </ul>
    <ul v-if="showList" class="multiSelectList">
      <li v-for="option in filteredOptions" :key="option">
        <input type="checkbox" v-model='selectedItems' :id="option"
          @change="$emit('update:modelValue', selectedItems); showList = !showList" :value="option"><label
          :for="option">{{
            option
          }}</label>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  props: {
    options: {
      default: [],
      required: true
    },
    label: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
      required: false
    },
  },
  data() {
    return {
      selectedItems: [],
      searchQuery: '',
      showList: false
    };
  },
  created() {
    let that = this;
    window.addEventListener('click', function (e) {
      const isOutFilterClick = that.$el.contains(e.target.closest('.multiSelect'));
      if (!isOutFilterClick) {
        that.showList = false;
      }
    });
  },
  computed: {
    filteredOptions() {
      if (this.searchQuery) {
        return this.options.filter((item) => item.toLowerCase().includes(this.searchQuery.toLowerCase()));
      } else {
        return this.options;
      }
    }
  },
};
</script>
<style scoped>
.multiSelect {
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  position: relative;
}

.multiSelect>label {
  padding: 0;
}

ul {
  padding: 0px;
  border: 1px solid var(--darkgray);
  list-style: none;
  margin: 0;
  border-radius: 5px;
}

.active>ul,
.multiselect {
  border-color: var(--active-border);
}

li {
  margin: 1px 0;
}

.searchList {
  flex-grow: 1;
  display: flex;
}

input[type="search"] {
  min-width: 50px;
  height: 30px;
  border: none;
  margin: -3px -5px -2px -3px;
}

.selectedItems {
  min-height: 35px;
  display: flex;
  flex-wrap: wrap;
}

.selectedItems li {
  background: var(--gray);
  margin: 3px;
  border: 1px solid var(--darkgray);
  border-radius: 5px;
  padding: 1px 3px 0 0;
  align-items: center;
}

label {
  width: 100%;
  align-items: center;
  padding: 5px 3px 0px 7px;
  /* font-weight:normal; */
  justify-content: start;
  height: 26px;
}

label>svg {
  margin: -7px 0 0 7px;
}

input {
  width: 0;
  height: 0;
  margin: 0;
}

.multiSelectList {
  border-top: 0px;
  position: absolute;
  float: left;
  top: 100%;
  width: 100%;
  box-sizing: border-box;
  background: #fff;
  z-index: 9;
}

.multiSelectList input:checked+label {
  background-color: var(--gray);
}
</style>
