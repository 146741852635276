<template>
  <div class="filterContainer">
    <div>
      <div>
        <div class="perPage">
          <label for="perPage">Page Size</label>
          <select @change="setPageSize" id="perPage">
            <option>25</option>
            <option>50</option>
            <option>100</option>
            <option>200</option>
            <option>500</option>
          </select>
        </div>
      </div>

      <search-filter-form @submit-form="submitSlugs"></search-filter-form>

      <div v-if="isDeleteAssets" class="deleteAssets"><base-button @click="deleteAssets" mode="iconBtn"
          title="Delete Assets"><font-awesome-icon icon="fa-trash" /></base-button></div>

      <div class="filterDropdown" :class="{ active: showDropDown }">
        <a href="#" @click.prevent="showDropDown = !showDropDown" title="More Filters">
          <font-awesome-icon icon="fa-angle-double-down" /></a>
      </div>
    </div>
    <div>
      <base-card class="filterDropdownList" :class="{ active: showDropDown }">
        <form @submit.prevent="submitFilters">
          <base-multiselect @change="submitFilters" :options="assetsFilter?.phases" label="Phase"
            v-model="phases"></base-multiselect>
          <base-multiselect @change="submitFilters" :options="assetsFilter?.states" label="State"
            v-model="states"></base-multiselect>
          <base-multiselect @change="submitFilters" :options="assetsFilter?.statuses" label="Status"
            v-model="statuses"></base-multiselect>
        </form>
      </base-card>
    </div>
    <base-dialog :show="showDeleteDialog" title="Delete Assets" @cancel="closeDialog" @confirm="handleDelete">
      <p>Do you really want to Delete Selected Assets ?</p>
    </base-dialog>
  </div>
</template>
<script>
import SearchFilterForm from './SearchFilterForm.vue';

export default {
  props: ['assetsFilter', 'isDeleteAssets'],
  data() {
    return {
      perPage: 25,
      phases: [],
      statuses: [],
      states: [],
      slugs: {},
      showDropDown: false,
      showDeleteDialog: false
    };
  },
  components: {
    SearchFilterForm
  },
  emits: ['submit-filter', 'delete-assets'],
  computed: {
    filterData() {
      return {
        per_page: this.perPage, phases: this.phases, statuses: this.statuses, states: this.states, slugs: this.slugs
      };
    },
  },
  methods: {
    setPageSize(event) {
      this.perPage = parseInt(event.target.value);
      this.$emit('submit-filter', this.filterData);
    },
    submitFilters() {
      this.$emit('submit-filter', this.filterData);
    },
    submitSlugs(filterFormData) {
      this.slugs = filterFormData?.slugs;
      this.$emit('submit-filter', this.filterData);
    },
    deleteAssets() {
      this.showDeleteDialog = true;
    },
    handleDelete() {
      this.showDeleteDialog = false;
      this.$emit('delete-assets');
    },
    closeDialog() {
      this.showDeleteDialog = false;
    },
    closeDropDown(e) {
      const isOutFilterClick = !this.$el.contains(e.target);
      if (isOutFilterClick) {
        this.showDropDown = false;
      }
    }
  },
  mounted() {
    document.addEventListener('click', this.closeDropDown);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.closeDropDown);
  }
};
</script>
<style scoped>
.filterContainer>div:first-child {
  padding: 10px 20px 15px 20px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  position: relative
}

.filterDropdownList {
  display: flex;
  flex-wrap: wrap;
  padding: 15px 0 20px 20px;
  align-items: flex-start;
  position: absolute;
  background: rgb(255, 255, 255);
  width: 280px;
  right: -310px;
  z-index: 9;
  transition: 0.3s;
}

.filterDropdownList.active {
  right: 30px
}

.perPage {
  position: absolute;
  left: 15px;
  top: 10px
}

.perPage,
.perPage>select {
  width: 85px;
  border-radius: 5px;
}

.searchContainer {
  min-width: 40%;
}

.deleteAssets {
  margin-left: 12px;
}

.deleteAssets .iconBtn {
  margin: 0;
  height: 36px;
}

.filterDropdownList {
  padding: 5px;
  max-height: calc(100% - 420px);
  overflow: auto;
}

.filterDropdown {
  display: inline-flex;
  height: 36px;
  margin-left: 12px;
}

.filterDropdown a {
  padding: 7px 8px;
  border: 1px solid var(--darkgray);
  border-radius: 5px;
  background: #fff;
  color: var(--text-color);
}

.filterDropdown a>svg,
.deleteAssets svg {
  color: #565657;
  font-size: 14px;
  vertical-align: middle;
}

.filterDropdown.active svg {
  transform: rotate(180deg);
}

.filterDropdownList .multiSelect {
  margin: 10px;
  width: 250px;
}

select {
  height: 36px;
}

button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

@media only screen and (min-width: 1230px) {
  .searchContainer {
    min-width: 420px;
  }
}
</style>
