<template>
  <main>
    <div class="formHeadTitle">
      <h2>Update Cohort</h2>
      <p>
        <router-link to="/cohorts">Cohorts</router-link> <font-awesome-icon icon="fa-caret-right"></font-awesome-icon>
        Update Cohort
      </p>
    </div>
    <base-card v-if="isLoading" class="loadingCard">
      <base-spinner></base-spinner>
    </base-card>
    <cohort-form v-else-if="cohort" @submit-form="updateCohort" :cohort="cohort" mode="update"
      :error="error"></cohort-form>
    <base-card v-else>
      <p class="centerAlign">No Cohort Found</p>
    </base-card>
  </main>
</template>

<script>
import { useCohortStore } from '@/store/cohorts/index.js';
import { useApiResponseStore } from '@/store/apiResponse';
import { useApiRequest } from '@/composables/useApiRequest';
import CohortForm from '@/components/cohort/CohortForm.vue';

export default {
  props: ['slug'],
  components: {
    CohortForm,
  },
  created() {
    this.storeCohort = useCohortStore();
    this.apiRequest = useApiRequest();
    this.storeApiResponse = useApiResponseStore();
    this.loadCohort();
  },
  data() {
    return {
      error: null
    };
  },
  computed: {
    isLoading() {
      return this.apiRequest.isLoading.value;
    },
    cohort() {
      return this.storeCohort.getCohort;
    },
  },
  methods: {
    async loadCohort() {
      await this.apiRequest.handleRequest(this.storeCohort.fetchCohort, this.slug);
    },
    async updateCohort(data) {
      this.apiRequest.clearError();
      try {
        await this.apiRequest.handleRequest(this.storeCohort.updateCohort, data, this.slug);
        this.storeApiResponse.setSuccessMsg(`Cohort : ${this.slug} is Updated`, 'back');
      } catch (err) {
        if (err.response.status === 422) {
          this.error = err.response.data.errors;
        }
        else {
          this.storeApiResponse.setError(err.message);
        }
      }
    },
  },
};
</script>
<style>
.loadingCard {
  padding: 25px;
}

.centerAlign {
  padding: 10px;
  text-align: center;
}
</style>

