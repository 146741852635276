<template>
  <main>
    <div class="formHeadTitle">
      <h2>Import Cohorts</h2>
      <p>
        <router-link to="/cohorts">Cohorts</router-link> <font-awesome-icon icon="fa-caret-right"></font-awesome-icon>New
        Import Cohorts
      </p>
    </div>
    <base-card v-if="isLoading"><base-spinner></base-spinner>
    </base-card>
    <base-card v-else>
      <div>
        <form @submit.prevent="submitForm">
          <div class="imageWrapper">
            <font-awesome-icon icon="fa-file-import" />
          </div>
          <p>Import your XLS/CSV sheet to sync up cohorts</p>
          <div>
            <label class="fileUpload">
              <input type="file" @input="uploadFile" />
              <span>{{ fileName || 'Choose File...' }}</span>
            </label>
          </div>
          <div class="footerBtn">
            <base-button mode="primaryBtn">Submit</base-button>
          </div>
        </form>
      </div>
    </base-card>
  </main>
</template>
<script>
import { useCohortImportStore } from '@/store/cohorts/import';
import { useApiResponseStore } from '@/store/apiResponse';
import { useApiRequest } from '@/composables/useApiRequest';

export default {
  data() {
    return {
      fileName: '',
      file: '',
    };
  },
  created() {
    this.storeCohortImport = useCohortImportStore();
    this.apiRequest = useApiRequest();
    this.storeApiResponse = useApiResponseStore();
  },
  computed: {
    isLoading() {
      return this.apiRequest.isLoading.value;
    },
  },
  methods: {
    uploadFile(e) {
      this.file = e.target?.files[0];
      this.fileName = this.file.name;
    },
    submitForm() {
      if (this.file) {
        const formdata = new FormData();
        formdata.append('file', this.file);
        this.importCohortFile(formdata);
      }
    },
    async importCohortFile(data) {
      this.apiRequest.clearError();
      try {
        await this.apiRequest.handleRequest(this.storeCohortImport.addCohortsImport, data);
        this.storeApiResponse.setSuccessMsg('File have been Imported', '/cohorts');
      } catch (err) {
        this.storeApiResponse.setError(err.message);
      }
    },
  }
};
</script>
<style scoped>
.card {
  text-align: center;
  padding: 40px;
  max-width: 450px;
  margin: auto;
  color: #666;
}

.imageWrapper svg {
  font-size: 64px;
  color: #999;
}

p {
  margin: 30px
}

input[type="file"] {
  display: none;
}

.fileUpload {
  position: relative;
  display: inline-block;
  cursor: pointer;
  width: 280px;
  padding: 0;
  line-height: 1.5;
  font-weight: normal;
  text-align: left;
  height: 48px;
}

.fileUpload>span {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 5;
  height: 20px;
  padding: 13px;
  background-color: #fff;
  border: 0.075rem solid #ddd;
  border-radius: 0.25rem;
  box-shadow: inset 0 0.2rem 0.4rem rgba(0, 0, 0, .05);
}

.fileUpload>span:before {
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  z-index: 6;
  display: block;
  content: "Browse";
  height: 20px;
  padding: 13px;
  line-height: 1.5;
  color: #555;
  background-color: #eee;
  border: 1px solid #ddd;
  border-radius: 0 5px 5px 0;
}

.footerBtn {
  justify-content: center;
}
</style>
