<template>
  <tr>
    <td>
      <router-link :to="financierDetailLink">{{ financier.slug }}</router-link>
    </td>
    <td v-for="item in colItem" :key="item">
      {{ item }}
    </td>
    <td class="actionBtn"><base-button :to="financierDetailLink" mode="primaryBtn iconBtn" link><font-awesome-icon
          icon="fa-eye" /></base-button><base-button :to="financierUpdateLink" mode="primaryBtn iconBtn"
        link><font-awesome-icon icon="fa-edit" /></base-button><base-button @click="deleteFinancier"
        mode="primaryBtn iconBtn"><font-awesome-icon icon="fa-trash" /></base-button>
    </td>
  </tr>
</template>
<script>
import '@/assets/table.css';
export default {
  props: [
    'financier',
  ],
  computed: {
    financierDetailLink() {
      return `${this.$route.path}/${this.financier.slug}`;
    },
    financierUpdateLink() {
      return `${this.$route.path}/${this.financier.slug}/edit`;
    },
    colItem() {
      const { name, iban, bic, address } = this.financier;
      return { name, iban, bic, address };
    },
  },
  methods: {
    deleteFinancier() {
      this.$emit('delete-financier', this.financier.slug);
    }
  }
};
</script>
