import { validateFn } from './commonValidator';

//allow alphanumeric and underscore
export const validateSlug = (value) => {
  if (/^[a-z0-9_]+$/.test(value)) {
    return;
  } else {
    return 'Accept alphanumeric, lowercase and underscores';
  }
};

//min length 5 char, allow alphanumeric and -, &
export const validateName = (value) => {
  const param = {
    value,
    pattern: '^[A-Za-z0-9& ,-]+$',
    min: 5,
    errMsg: 'Accept alphanumeric and -, &',
  };

  return validateFn(param);
};

//min length 10 char, allow alphanumeric and -, &, apostrophe, round brackets
export const validateAddress = (value) => {
  const param = {
    value,
    pattern: /^[A-Za-zäöüßÄÖÜ0-9()'& ,-]+$/,
    min: 10,
    errMsg: 'Accept alphanumeric(äöüßÄÖÜ) and -, &, \', (, )',
  };

  return validateFn(param);
};
