<template>
  <h1>HELLO INVOICES</h1>
  <base-card class="tableCard">
    <table>
      <tr>
        <th v-for="col in tableCol" :key="col">{{ col }}</th>
      </tr>
      <cohort-invoice-item v-for="invoice in invoices" :key="invoice.source_id" :invoice="invoice"></cohort-invoice-item>
    </table>
  </base-card>
  <pagination-item :total="totalResults" :per-page="perPage" :current-page="currentPage"  @setPage="onPageChange">
  </pagination-item>
  <div v-if="!hasInvoices && !isVisibleRetrieveBtn" class="footerBtn">
    <base-button mode="primaryBtn" @click="retrieveCohortInvoices">Retrieve Invoices</base-button>
  </div>
</template>
<script>

import { useApiResponseStore } from '@/store/apiResponse';
import { useApiRequest } from '@/composables/useApiRequest';
import { useCohortInvoiceStore } from '@/store/cohorts/invoices.js';
import CohortInvoiceItem from '@/components/cohort/CohortInvoiceItem.vue';
import PaginationItem from '@/components/pagination/PaginationItem.vue';

export default {
  data() {
    return {
      currentPage: 1,
      perPage: 25,
      tableCol: ['Asset Slug', 'Source Id', 'Created At'],
      isRetrievingRequested: false,
    };
  },
  props: ['slug'],
  components: { CohortInvoiceItem, PaginationItem },
  created() {
    this.storeCohortInvoice = useCohortInvoiceStore();
    this.apiRequest = useApiRequest();
    this.storeApiResponse = useApiResponseStore();
    this.loadCohortInvoices();
  },
  computed: {
    isLoading() {
      return this.apiRequest.isLoading.value;
    },
    invoices() {
      return this.storeCohortInvoice.getCohortInvoices;
    },
    totalResults() {
      return this.storeCohortInvoice.totalCount;
    },

    isVisibleRetrieveBtn() {
      return this.storeCohortInvoice.getCohortInvoices.length == 0 && this.isRetrievingRequested;
    },
  },
  methods: {
    async loadCohortInvoices() {
      const paramObj = {
        cohort_slug: this.slug,
        page: this.currentPage,
        per_page: this.perPage
      };
      await this.apiRequest.handleRequest(this.storeCohortInvoice.fetchCohortInvoices, paramObj);
    },
    async retrieveCohortInvoices() {
      const paramObj = {
        cohort_slug: this.slug,
      };
      try {
        await this.apiRequest.handleRequest(this.storeCohortInvoice.retrieveCohortInvoices, paramObj);
        this.storeApiResponse.setSuccessMsg('The request has been starting.');
      } catch (err) {
        this.storeApiResponse.setError('Something is wrong. Try later!');
      }
      this.isRetrievingRequested = true;
    },
    onPageChange(page) {
      this.currentPage = page;
      this.loadCohortInvoices();
    },
  }
};
</script>
