<template>
  <div class="filterContainer">
    <div>
      <div class="perPage">
        <label for="perPage">Page Size</label>
        <select @change="submitFilters" v-model="perPage">
          <option>10</option>
          <option>25</option>
          <option>50</option>
          <option>100</option>
          <option>500</option>
        </select>
      </div>
      <date-range @submit-form="submitDateFilters"></date-range>
      <search-filter-form @submit-form="submitSlugs" :option="searchOption.slug"></search-filter-form>
      <div class="filterDropdown" :class="{ active: showDropDown }">
        <a href="#" @click.prevent="showDropDown = !showDropDown" title="More Filters"><font-awesome-icon
            icon="fa-angle-double-down" /></a>
      </div>
    </div>

    <base-card class="filterDropdownList" :class="{ active: showDropDown }">
      <base-multiselect @change="submitFilters" :options="['synced', 'draft']" label="Status"
        v-model="statuses"></base-multiselect>
      <base-multiselect @change="submitFilters" :options="financiers" label="Financiers"
        v-model="financier_slugs"></base-multiselect>
      <base-multiselect @change="submitFilters" :options="sellers" label="Sellers"
        v-model="seller_slugs"></base-multiselect>
      <base-multiselect @change="submitFilters" :options="appInstances" label="App Instance"
        v-model="app_instances"></base-multiselect>
      <base-multiselect @change="submitFilters" :options="purchasedTypeComponents" label="Purchased Claim Type"
        v-model="purchased_type_components"></base-multiselect>
      <date-range @submit-form="submitDateFilters"
        :option="{ label: 'Handover Date', title: 'handover_date' }"></date-range>
      <date-range @submit-form="submitDateFilters"
        :option="{ label: 'Purchase Date', title: 'purchase_date' }"></date-range>
      <search-filter-form @submit-form="submitName" :option="searchOption.name"></search-filter-form>
    </base-card>
  </div>
</template>
<script>
import SearchFilterForm from './SearchFilterForm.vue';
import DateRange from '@/components/filters/DateRange.vue';
import { useFinancierStore } from '@/store/financiers/index.js';
import { useSellerStore } from '@/store/sellers/index.js';
import { useCohortStore } from '@/store/cohorts/index.js';

export default {
  data() {
    return {
      storeFinancier: useFinancierStore(),
      storeSeller: useSellerStore(),
      perPage: 10,
      showDropDown: false,
      statuses: [],
      financier_slugs: [],
      seller_slugs: [],
      app_instances: [],
      purchased_type_components: [],
      slugs: '',
      name: '',
      searchFilter: '',
      searchOption: {
        slug: {
          name: 'slugs',
          title: 'Slug',
          outputType: 'array',
          placeholder: 'Type Slugs separated by space / newline / comma'
        },
        name: {
          name: 'name',
          title: 'Name',
          outputType: 'string',
          placeholder: 'Type Name to search'
        }
      }
    };
  },
  components: {
    SearchFilterForm,
    DateRange
  },
  emits: ['submit-filter'],
  created() {
    this.loadFinanciers();
    this.loadSellers();
    this.loadPurchasedTypeComponents();
    this.storeCohort = useCohortStore();
    this.loadAppInstancesAndCurrencies();
  },
  computed: {
    financiers() {
      return this.storeFinancier.getFinanciers?.map(financier => financier.slug);
    },
    sellers() {
      return this.storeSeller.getSellers?.map(seller => seller.slug);
    },
    purchasedTypeComponents() {
      return this.storeSeller.getPurchasedTypeComponents;
    },
    appInstances() {
      return this.storeCohort.appInstances;
    },
  },
  methods: {
    async loadFinanciers() {
      await this.storeFinancier.fetchFinanciers();
    },
    async loadSellers() {
      await this.storeSeller.fetchSellers();
    },
    async loadPurchasedTypeComponents() {
      const paramObj = {
        sellers: {
          columns: ['purchased_type_component']
        }
      };
      await this.storeSeller.fetchPurchasedTypeComponents(paramObj);
    },
    async loadAppInstancesAndCurrencies() {
      await this.storeCohort.fetchAppInstancesAndCurrencies();
    },
    submitDateFilters(filterFormData) {
      this.searchFilter = { ...this.searchFilter, ...filterFormData };
      this.submitFilters();
    },
    submitName(filterFormData) {
      this.name = filterFormData.name;
      this.submitFilters();
    },
    submitSlugs(filterFormData) {
      this.slugs = filterFormData.slugs;
      this.submitFilters();
    },
    submitFilters() {
      const filterData = {
        per_page: this.perPage, statuses: this.statuses, financier_slugs: this.financier_slugs, seller_slugs: this.seller_slugs, app_instances: this.app_instances, purchased_type_components: this.purchased_type_components, slugs: this.slugs, name: this.name,
        ...this.searchFilter
      };
      this.$emit('submit-filter', filterData);
    },
    closeDropDown(e) {
      const isOutFilterClick = !this.$el.contains(e.target);
      if (isOutFilterClick) {
        this.showDropDown = false;
      }
    }
  },
  mounted() {
    document.addEventListener('click', this.closeDropDown);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.closeDropDown);
  }
};
</script>
<style scoped>
.filterContainer>div:first-child {
  display: flex;
  padding: 10px 20px 15px 20px;
  align-items: flex-end;
  position: relative
}

.filterDropdownList {
  display: flex;
  flex-wrap: wrap;
  padding: 15px 0 20px 20px;
  align-items: flex-start;
  position: absolute;
  background: rgb(255, 255, 255);
  width: 280px;
  right: -310px;
  max-height: calc(100% - 320px);
  overflow-y: auto;
  z-index: 9;
  transition: 0.3s;
}

.filterDropdownList.active {
  right: 30px
}

.filterDropdownList>div {
  display: flex;
  margin: 10px 20px 5px 0;
  min-width: 250px;
}

.searchContainer {
  width: 30%;
  position: absolute;
  right: 60px;
}

.filterDropdownList .searchContainer {
  position: relative;
  width: 100%;
  right: 0;
}

.filterDropdown a {
  padding: 7px 8px;
  border: 1px solid var(--darkgray);
  border-radius: 5px;
  background: #fff;
  color: var(--text-color);
}

.filterDropdown a>svg {
  color: #565657;
  font-size: 14px;
  vertical-align: middle;
}

.filterDropdown.active svg {
  transform: rotate(180deg);
}

.filterDropdownList>form {
  display: flex;
}

.perPage,
.perPage>select {
  width: 85px;
  border-radius: 5px;
}

.multiSelect {
  width: calc(25% - 20px) !important;
}

select {
  height: 34px;
}

.filterDropdown {
  position: absolute;
  right: 15px;
  display: inline-flex;
}

.multiSelect,
.dateRange,
.perPage {
  margin-right: 20px;
}

@media only screen and (min-width: 1230px) {
  .searchContainer {
    width: 420px;
  }
}
</style>
