<template>
  <main>
    <div class="formHeadTitle">
      <h2>Financier Details</h2>
      <p>
        <router-link to="/financiers">Financiers</router-link> <font-awesome-icon
          icon="fa-caret-right"></font-awesome-icon>Financier Details
      </p>
    </div>
    <base-card v-if="isLoading">
      <base-spinner></base-spinner>
    </base-card>

    <base-card v-else-if="financier" class="detailCard">
      <div>
        <p><label>Slug:</label> <span>{{ financier.slug }}</span></p>
        <p><label>Name:</label> <span>{{ financier.name }}</span></p>
        <p><label>IBAN:</label> <span>{{ financier.iban }}</span></p>
        <p><label>BIC:</label><span>{{ financier.bic }}</span></p>
        <p><label>Address:</label><span>{{ financier.address }}</span></p>
      </div>
      <div class="footer-btn">
        <base-button mode="danger" @click="deleteFinancier">Delete</base-button>
        <base-button :to="financierDetailLink" mode="primaryBtn" link>Update</base-button>
      </div>
    </base-card>
    <base-card v-else>
      <p class="centerAlign">No financier Found</p>
    </base-card>
    <base-dialog :show="showDeleteDialog" title="Delete Financier" @cancel="closeDialog" @confirm="handleDelete">
      <p>Do you really want to Delete <b>Slug:{{ slug }}</b> ?</p>
    </base-dialog>
  </main>
</template>

<script>
import { useFinancierStore } from '@/store/financiers/index.js';
import { useApiResponseStore } from '@/store/apiResponse';
import { useApiRequest } from '@/composables/useApiRequest';

export default {

  props: ['slug'],
  data() {
    return {
      showDeleteDialog: false,
    };
  },
  created() {
    this.storeFinancier = useFinancierStore();
    this.apiRequest = useApiRequest();
    this.storeApiResponse = useApiResponseStore();
    this.loadFinancier();
  },
  computed: {
    isLoading() {
      return this.apiRequest.isLoading.value;
    },
    financier() {
      return this.storeFinancier.getFinancier;
    },
    financierDetailLink() {
      return `${this.slug}/edit`;
    }
  },
  methods: {
    async loadFinancier() {
      await this.apiRequest.handleRequest(this.storeFinancier.fetchFinancier, this.slug);
    },
    deleteFinancier() {
      this.showDeleteDialog = true;
    },
    closeDialog() {
      this.showDeleteDialog = false;
    },
    async handleDelete() {
      this.apiRequest.clearError();
      this.showDeleteDialog = false;
      try {
        await this.apiRequest.handleRequest(this.storeFinancier.deleteFinancier, this.slug);
        this.storeApiResponse.setSuccessMsg(`Financiers : ${this.slug} is deleted`, '/financiers');
      } catch (err) {
        this.storeApiResponse.setError(err.message);
      }
    }
  },
};
</script>
<style scoped src="@/assets/detailView.css">
</style>

