<template>
  <div class="dateRange">
    <label>{{ option.label }}</label>
    <div>
      <input type="date" @change="submitForm" v-model="fromDate" :max="toDate" />
      <span>to</span>
      <input type="date" @change="submitForm" v-model="toDate" :min="fromDate" />
      <button @click="clearForm" :class="{ visible: fromDate || toDate }"><font-awesome-icon icon="fa-xmark"
          title="Clear dates" /></button>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    option: {
      default: {
        label: 'Created Date',
        title: 'created_at',
      }
    }
  },
  data() {
    return {
      fromDate: '',
      toDate: '',
    };
  },
  computed: {
    validateDates() {
      const d1 = new Date(this.fromDate).getTime();
      const d2 = new Date(this.toDate).getTime();
      if (d1 && d2 && (d1 > d2)) {
        return false;
      } else {
        return true;
      }
    }
  },
  methods: {
    submitForm() {
      if (this.validateDates) {
        const formData = {};
        formData[`${this.option.title}[from]`] = this.fromDate;
        formData[`${this.option.title}[to]`] = this.toDate;
        this.$emit('submit-form', formData);
      }
    },
    clearForm() {
      if (this.fromDate || this.toDate) {
        this.fromDate = '';
        this.toDate = '';
        this.submitForm();
      }
    }
  }
};
</script>
<style scoped>
.dateRange {
  display: flex;
  flex-direction: column;
}

.dateRange>div {
  display: flex;
  align-items: center;
}

.dateRange span {
  border: 1px solid var(--darkgray);
  padding: 8px 6px;
  background: var(--gray);
  margin: 0 -1px;
  height: 17px;
}

input[type="date"] {
  height: 35px;
  min-width: 110px;
  display: inline-block;
  padding-right: 3px;
}

.dateRange input[type="date"]:first-child {
  border-radius: 5px 0 0 5px;
}

.dateRange input[type="date"]:last-child {
  border-radius: 0 5px 5px 0;
}

input:invalid {
  border: 1px solid var(--red);
}

button {
  margin: 0 6px;
  padding: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #eee;
  color: var(--text-color);
  visibility: hidden;
}

button.visible {
  visibility: visible;
}
</style>
