<template>
  <base-card class="reportFormContainer">
    <form @submit.prevent="submitForm">
      <div class="formContainer">
        <div class="form-control">
          <label for="reportType"> Report Type </label>
          <select id="reportType" v-model="reportType">
            <option>cohort_handedover</option>
          </select>
        </div>
        <div class="form-control">
          <label for="reportFormat"> Report Format </label>
          <select id="reportFormat" v-model="reportFormat">
            <option>xlsx</option>
            <option>csv</option>
          </select>
        </div>
      </div>

      <div class="footerBtn"><base-button mode="primaryBtn" :disabled="isFormInvalid">Submit</base-button></div>

    </form>
  </base-card>
</template>
<script>
import { validateIfEmpty } from '@/utils/validators/commonValidator';

export default {
  data() {
    return {
      reportType: '',
      reportFormat: '',
    };
  },
  emits: ['submit-form'],
  computed: {
    isFormInvalid() {
      return validateIfEmpty(this.reportType) || validateIfEmpty(this.reportFormat);
    }
  },
  methods: {
    submitForm() {
      const formData =
      {
        file_format: this.reportFormat,
        report_type: this.reportType
      };
      this.$emit('submit-form', formData);
    },
  },
};
</script>
<style scoped src="@/assets/form.css">
</style>

<style scoped>
.reportFormContainer {
  padding: 0 25px 5px 5px;
  margin: 0 0 25px 0;
  max-width: 100%;
}

.reportFormContainer form {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.formContainer {
  display: flex;
  width: 80%;
}

.formContainer label {
  width: auto
}
</style>
