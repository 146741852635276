<template>
  <div class="sidebar" :class="{ active }">
    <button @click="togglaNav">
      <font-awesome-icon icon="bars" />
    </button>

    <transition name="slide">
      <div class="sidebar-panel">
        <slot></slot>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  data() {
    return {
      active: true,
    };
  },
  methods: {
    togglaNav() {
      this.active = !this.active;
      this.$emit('clicked', this.active);
    },
  },
};
</script>
<style scoped>
.sidebar-panel {
  overflow-y: auto;
  position: fixed;
  left: 0;
  top: 0;
  transition: .5s;
  background: #fff;
  box-shadow: 0 12px 30px rgba(80, 143, 244, .1);
  height: 100vh;
  z-index: 9;
  padding: 80px 0px 2rem 0px;
  width: 230px;
  box-sizing: border-box;
  left: -230px;
}

.active .sidebar-panel {
  left: 0;
}

button {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  border: 0;
  padding: 22px;
  background: #fff;
  color: var(--dark-fontIcon-color);
  font-size: 20px;
}
</style>
