import { defineStore } from 'pinia';
import api from '@/services/api.js';

export const useFinancierStore = defineStore({
  id: 'FinancierStore',
  state: () => ({
    financiers: [],
    currentFinancier: null,
  }),

  getters: {
    getFinanciers: (state) => state.financiers,
    getFinancier: (state) => state.currentFinancier,
  },
  actions: {
    async fetchFinanciers() {
      const responseData = await api.getResourceList('financiers');
      this.financiers = responseData?.data || [];
    },
    async fetchFinancier(slug) {
      this.currentFinancier = null;
      const responseData = await api.getResource('financiers', slug);
      this.currentFinancier = responseData?.financier || null;
    },
    async addFinancier(data) {
      await api.addResource('financiers', data);
    },
    async updateFinancier(data, prevSlug) {
      await api.updateResource('financiers', prevSlug, data);
    },
    async deleteFinancier(slug) {
      return api.deleteResource('financiers', slug);
    },
  },
});
