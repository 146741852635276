import { defineStore } from 'pinia';
import api from '@/services/api.js';
import { THIRD_PARTY_LOGOUT_URL } from '@/utils/url.js';

export const useAuthStore = defineStore({
  id: 'AuthStore',
  state: () => ({
    currentUser: localStorage.liquandumUser,
  }),
  getters: {
    getCurrentUser: (state) => state.currentUser,
    isAuthenticated: (state) => !!state.currentUser,
  },
  actions: {
    async authUser(code) {
      const responseData = await api.addResource('sessions', {
        code,
      });
      const { email, csrf_token } = responseData;
      localStorage.setItem('liquandumCSRFtoken', csrf_token);
      localStorage.setItem('liquandumUser', email);
      this.currentUser = email || null;
    },
    async deleteUser() {
      await api.deleteResource('sessions');
      localStorage.removeItem('liquandumUser');
      localStorage.removeItem('liquandumCSRFtoken');
      this.currentUser = null;
      location.href = THIRD_PARTY_LOGOUT_URL;
    },
    tryLogin() {
      this.currentUser = localStorage.getItem('liquandumUser') || null;
    },
  },
});
