<template>
  <tr>
    <td v-for="item in colItems" :key="item">
      <router-link v-if="item == colItems.cohortName" :to="cohortDetailLink">{{ failedJob.cohort_slug }}</router-link>
      <template v-else>
        {{ item }}
      </template>
    </td>
  </tr>
</template>
<script>
import '@/assets/table.css';
export default {
  props: [
    'failedJob',
  ],
  computed: {
    cohortDetailLink() {
      return `cohorts/${this.failedJob.cohort_slug}`;
    },
    colItems() {
      const items = {
        cohortName: this.failedJob.cohort_name,
        assetSlugs: this.failedJob.asset_slugs,
        time: this.failedJob.created_at
      };
      return items;
    },
  },
};
</script>
