<template>
  <main class="greenBg">
    <section class="headSection">
      <div class="headTitle">
        <h2>Reports</h2>
      </div>
    </section>
    <section>
      <all-reports></all-reports>
    </section>
  </main>
</template>
<script>
import AllReports from '@/pages/reports/AllReports.vue';
export default {
  components: {
    AllReports
  }
};
</script>
