import { validateFn } from './commonValidator';

//allow alphanumeric and _,&, ,- Min - max char :5 - 100
export const validateCohortName = (value) => {
  const param = {
    value,
    pattern: '^[A-Za-z0-9_& ,-]+$',
    min: 5,
    max: 100,
    errMsg: 'Accept alphanumeric and - _,&',
  };
  return validateFn(param);
};

//allow alphanumeric and _,&, ,- Min - max char :5 - 1000
export const validateCohortDescription = (value) => {
  const param = {
    value,
    pattern: '^[A-Za-z0-9_& ,-.]+$',
    min: 5,
    max: 1000,
    errMsg: 'Accept alphanumeric and - _,&.',
  };
  return validateFn(param);
};
