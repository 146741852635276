<!-- This components takes input as strings seperated by comma,space,newline and return paramObject{key:value}, where value can be string or collection of string depending on options.outputType-->

<template>
  <div class="searchContainer" :id="id">
    <form @submit.prevent="submitForm">
      <label>{{ option.title }}</label>
      <div>
        <base-input @search="handleClear" type="search" :placeholder="option.placeholder" :title="option.placeholder"
          v-model="inputString"></base-input>
        <base-button mode="secondaryBtn">Search</base-button>
      </div>
    </form>
  </div>
</template>
<script>
export default {
  props: {
    id: {
      type: String,
      default: '',
    },
    option: {
      default: {
        name: 'slugs',
        title: 'Slug',
        outputType: 'array',
        placeholder: 'Type Slugs separated by space / newline / comma'
      }
    }
  },
  data() {
    return {
      inputString: '',
    };
  },
  emits: ['submit-form'],
  methods: {
    submitForm() {
      let searchData;
      if (this.option.outputType === 'string') {
        searchData = this.inputString.trim();
      }
      else {
        searchData = this.inputString.replace(/(,|\n|\t|\r)+/g, ' ').trim().split(' ').filter(i => i);//converting strings to array without empty element
      }
      const formData = {};
      formData[this.option.name] = searchData;
      this.$emit('submit-form', formData);
    },
    handleClear() {
      if (!event.target.value) {
        this.$emit('submit-form', {});
      }
    },
  }
};

</script>
<style scoped>
.searchContainer,
.searchContainer>form>div {
  display: flex;
  align-items: center;
}

form {
  width: 100%
}

.searchContainer>form>div,
.searchContainer .form-control {
  width: 100%
}

.searchContainer:deep(input) {
  width: 100%;
  border: none;
  padding-left: 7px;
  border-radius: 5px;
}

.searchContainer form>div {
  border: 1px solid var(--darkgray);
  height: 34px;
  border-radius: 5px;
  max-width: 420px;
  min-width: 200px;
  border-right: none;
}

button.secondaryBtn {
  padding: 9px 20px !important;
  font-size: 14px;
}
</style>
