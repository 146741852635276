import { defineStore } from 'pinia';
import api from '@/services/api.js';

export const useFailedJobsStore = defineStore({
  id: 'storeFailedJob',
  state: () => ({
    failedJobs: [],
  }),

  getters: {
    getFailedJobs: (state) => state.failedJobs,
  },

  actions: {
    async fetchFailedJobs() {
      const responseData = await api.getResourceList(
        'failed_asset_creation_jobs'
      );
      this.failedJobs = responseData?.data || [];
    },
  },
});
