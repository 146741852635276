<template>
  <main>
    <div class="formHeadTitle">
      <h2>New Cohort</h2>
      <p>
        <router-link to="/cohorts">Cohorts</router-link> <font-awesome-icon icon="fa-caret-right"></font-awesome-icon>New
        Cohort
      </p>
    </div>
    <cohort-form @submit-form="addCohort" mode="create" :error="error"></cohort-form>
  </main>
</template>

<script>
import CohortForm from '@/components/cohort/CohortForm.vue';
import { useCohortStore } from '@/store/cohorts/index.js';
import { useApiResponseStore } from '@/store/apiResponse';
import { useApiRequest } from '@/composables/useApiRequest';

export default {
  components: {
    CohortForm,
  },
  data() {
    return {
      error: null
    };
  },
  created() {
    this.storeCohort = useCohortStore();
    this.apiRequest = useApiRequest();
    this.storeApiResponse = useApiResponseStore();
  },
  computed: {
    isLoading() {
      return this.apiRequest.isLoading.value;
    },
  },
  methods: {
    async addCohort(data) {
      this.apiRequest.clearError();
      try {
        await this.apiRequest.handleRequest(this.storeCohort.addCohort, data);
        this.storeApiResponse.setSuccessMsg('A new Cohort is Added.', '/cohorts');
      } catch (err) {
        const { status, data } = err.response;
        if (status === 422 && data.errors.assets) {
          this.error = { 'slugsString': ': Duplicates found!' };
          let msg = '';
          const assetsErrMsg = data.errors.assets;
          for (const key in assetsErrMsg) {
            msg += `<p><b>${key}</b> : ${assetsErrMsg[key].join(', ')}</p>`;
          }
          this.storeApiResponse.setErrorMsg(msg, 'Duplicate Assets Found!');
        }
        else if (status === 422) {
          this.error = data.errors;
        }
        else {
          this.storeApiResponse.setError(err.message);
        }
      }
    },
  },
};
</script>
