<template>
  <main class="greenBg">
    <section class="headSection">
      <div class="headTitle">
        <h2>Cohorts</h2>
        <div class="buttonWrapper">
          <base-button to="/cohorts/import" mode='iconBtn whiteBtn' link title="Import Cohorts"><font-awesome-icon
              icon="fa-file-import" /></base-button>
          <base-button to="/cohorts/new" mode='whiteBtn' link>New Cohorts</base-button>
        </div>
      </div>
      <base-card>
        <filter-cohorts @submit-filter="submitFilters"></filter-cohorts>
      </base-card>
    </section>

    <section>
      <base-card v-if="isLoading">
        <base-spinner></base-spinner>
      </base-card>
      <template v-else-if="hasCohort">
        <base-card class="tableCard">
          <table>
            <tr>
              <th v-for="col in tableCol" :key="col">{{ col }}</th>
            </tr>
            <cohort-item v-for="cohort in cohorts" :key="cohort.slug" :cohort="cohort"
              @delete-cohort="deleteCohort"></cohort-item>
          </table>
        </base-card>

        <pagination-item :total="totalResults" :per-page="perPage" :current-page="currentPage"
          @setPage="onPageChange"></pagination-item>
      </template>
      <base-card v-else>
        <p class="centerAlign">No Cohort Found</p>
      </base-card>
    </section>
  </main>
  <base-dialog :show="showDeleteDialog && deleteSlug" title="Delete Cohort" @cancel="closeDialog" @confirm="handleDelete">
    <p>Do you really want to Delete the following cohort <b>Slug:{{ deleteSlug }}</b> ?</p>
  </base-dialog>
</template>

<script>
import { useCohortStore } from '@/store/cohorts/index.js';
import { useApiResponseStore } from '@/store/apiResponse';
import { useApiRequest } from '@/composables/useApiRequest';
import CohortItem from '@/components/cohort/CohortItem.vue';
import FilterCohorts from '@/components/filters/FilterCohorts.vue';
import PaginationItem from '@/components/pagination/PaginationItem.vue';

export default {
  components: {
    CohortItem,
    FilterCohorts,
    PaginationItem,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 10, //initial value
      deleteSlug: '',
      filterData: null,
      showDeleteDialog: false,
      tableCol: ['Status', 'Slug', 'Name', 'App Instance', 'Currency', 'Financier', 'Seller', 'Created Date', 'Handover Date', 'Purchase Date', 'Seller Invoice No', ' Invoice Amount', 'Purchased Claim Type', 'Total Assests', 'Purchase Price', 'Notional Price', 'Purchase Price Ratio', 'Actions']
    };
  },
  created() {
    this.storeCohort = useCohortStore();
    this.apiRequest = useApiRequest();
    this.storeApiResponse = useApiResponseStore();
    this.loadCohorts();
  },
  computed: {
    isLoading() {
      return this.apiRequest.isLoading.value;
    },
    hasCohort() {
      return this.storeCohort.getCohorts?.length > 0;
    },
    cohorts() {
      return this.storeCohort.getCohorts;
    },
    totalResults() {
      return this.storeCohort.totalResults;
    },
  },
  methods: {
    async loadCohorts() {
      const paramObj = {
        'page': this.currentPage,
        'per_page': this.perPage,
        ...this.filterData,
      };
      await this.apiRequest.handleRequest(this.storeCohort.fetchCohorts, paramObj);
    },
    submitFilters(filterData) {
      this.currentPage = 1;
      this.perPage = parseInt(filterData.per_page);
      this.filterData = filterData;
      this.loadCohorts();
    },
    onPageChange(page) {
      this.currentPage = page;
      this.loadCohorts();
    },
    deleteCohort(slug) {
      this.showDeleteDialog = true;
      this.deleteSlug = slug;
    },
    closeDialog() {
      this.showDeleteDialog = false;
    },
    async handleDelete() {
      this.apiRequest.clearError();
      this.showDeleteDialog = false;
      try {
        await this.apiRequest.handleRequest(this.storeCohort.deleteCohort, this.deleteSlug);
        this.loadCohorts();
        this.storeApiResponse.setSuccessMsg(`Cohort : ${this.deleteSlug} is Deleted.`);
      } catch (err) {
        this.storeApiResponse.setError(err.message);
      }
    }
  },
};
</script>
