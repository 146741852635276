<template>
  <header>
    <router-link to="/"><img src="../../assets/liquandumLogo.png" alt="logo" /></router-link>
    <nav v-if="user" ref="dropDown">
      <ul>
        <li @click="showdropDown = !showdropDown">
          <span class="userInitial">{{ user[0] }}</span>
        </li>
      </ul>
      <base-card v-if="showdropDown" class="userdropDown">
        <ul>
          <li>
            {{ user }}
          </li>
          <li @click="logout" class="logoutlink">
            Log Out
          </li>
        </ul>
      </base-card>
    </nav>
  </header>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { useAuthStore } from '@/store/auth/index.js';

const authStore = useAuthStore();
const user = computed(() => {
  return authStore.getCurrentUser;
});
const logout = (async () => {
  showdropDown.value = false;
  await authStore.deleteUser();
});

const showdropDown = ref(false);
onMounted(() => {
  document.addEventListener('click', closeDropDown);
});

const dropDown = ref(null);
const closeDropDown = ((e) => {
  const isOutFilterClick = dropDown?.value?.contains(e.target);
  if (!isOutFilterClick) {
    showdropDown.value = false;
  }
});
</script>
<style scoped>
header {
  display: flex;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 0.95);
  box-shadow: 0 1px 14px 0 rgba(0, 0, 0, 0.07);
  line-height: 1;
  height: 70px;
  z-index: 99;
  align-items: center;
  padding: 0 10px;
}

img {
  width: 150px;
}

ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

li {
  margin: 5px;
  padding: 12px;
  color: var(--dark-fontIcon-color);
  font-size: 16px;
  border-radius: 3px;
}

span.userInitial {
  width: 24px;
  height: 24px;
  background: var(--green-fontIcon);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  color: #fff;
  font-size: 16px;
  border-radius: 50%;
  text-transform: capitalize;
  border: 3px solid #fff;
  cursor: pointer;
}

span.userInitial:hover,
span.userInitial:focus {
  border-color: var(--accent-green)
}

.userdropDown {
  display: flex;
  flex-direction: column;
  margin-top: -5px;
  position: absolute;
  right: 20px;
  z-index: 999;
}

.userdropDown>ul {
  display: flex;
  flex-direction: column;
}

.logoutlink:hover {
  background-color: var(--lightgray);
  cursor: pointer;
}

a {
  margin-left: 55px;
  transition: 0.5s;
}

.shrink a {
  margin-left: 0px;
}
</style>
