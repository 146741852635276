import { useAuthStore } from '@/store/auth/index.js';
import { createRouter, createWebHistory } from 'vue-router';
import CohortsList from '@/pages/cohorts/CohortsList.vue';
import CohortDetails from '@/pages/cohorts/CohortDetails.vue';
import NewCohort from '@/pages/cohorts/NewCohort.vue';
import CohortsImport from '@/pages/cohorts/CohortsImport.vue';
import UpdateCohort from '@/pages/cohorts/UpdateCohort.vue';
import PortfolioView from '@/pages/portfolios/PortfolioView.vue';
import ReportsView from '@/pages/reports/ReportsView.vue';
import FinanciersList from '@/pages/financiers/FinanciersList.vue';
import FinancierDetails from '@/pages/financiers/FinancierDetails.vue';
import NewFinancier from '@/pages/financiers/NewFinancier.vue';
import UpdateFinancier from '@/pages/financiers/UpdateFinancier.vue';
import SellersList from '@/pages/sellers/SellersList.vue';
import SellerDetails from '@/pages/sellers/SellerDetails.vue';
import NewSeller from '@/pages/sellers/NewSeller.vue';
import UpdateSeller from '@/pages/sellers/UpdateSeller.vue';
import FailedJobsList from '@/pages/failed_jobs/FailedJobsList.vue';
import LoginPage from '@/pages/LoginPage.vue';
import NotFound from '@/pages/NotFound.vue';
import ServerError from '@/pages/ServerError.vue';

export const routes = [
  { path: '/', redirect: '/cohorts' },
  { path: '/cohorts', name: 'COHORTS', component: CohortsList },
  {
    path: '/cohorts/:slug',
    component: CohortDetails,
    props: true,
  },
  { path: '/cohorts/new', component: NewCohort },
  { path: '/cohorts/import', component: CohortsImport },
  { path: '/cohorts/:slug/edit', component: UpdateCohort, props: true },
  { path: '/portfolios', component: PortfolioView },
  {
    path: '/reports',
    component: ReportsView,
  },
  { path: '/financiers', component: FinanciersList },
  { path: '/financiers/:slug', component: FinancierDetails, props: true },
  { path: '/financiers/new', component: NewFinancier },
  { path: '/financiers/:slug/edit', component: UpdateFinancier, props: true },
  { path: '/sellers', component: SellersList },
  { path: '/sellers/:slug', component: SellerDetails, props: true },
  { path: '/sellers/new', component: NewSeller },
  { path: '/sellers/:slug/edit', component: UpdateSeller, props: true },
  { path: '/failed_jobs', component: FailedJobsList },
  { path: '/login', name: 'LOGIN', component: LoginPage },
  { path: '/404error', component: NotFound },
  { path: '/500error', component: ServerError },
  { path: '/:notFound(.*)', component: NotFound },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

const redirectToLogin = (route) => {
  if (route.name != 'LOGIN') {
    return {
      name: 'LOGIN',
      replace: true,
    };
  }
};

const redirectToHome = () => {
  return { name: 'COHORTS', replace: true };
};

router.beforeEach(async function (to) {
  const authStore = useAuthStore();
  const isAuthenticated = authStore.isAuthenticated;
  if (to.query.code && to.name == 'LOGIN') {
    await authStore.authUser(to.query.code);
    return redirectToHome(to);
  }
  if (!isAuthenticated) {
    return redirectToLogin(to);
  }
  if (isAuthenticated && to.fullPath === '/login') {
    return redirectToHome(to);
  }

  return true;
});

export default router;
