import { defineStore } from 'pinia';
import api from '@/services/api.js';

export const useAssetsFilterStore = defineStore({
  id: 'AssetsFilterStore',
  state: () => ({
    assetsFilters: null,
  }),

  getters: {
    getAssetsFilters: (state) => state.assetsFilters,
  },
  actions: {
    async fetchAssetsFilter(paramsObj) {
      const responseData = await api.getResourceList(
        'columns_values',
        paramsObj
      );
      this.assetsFilters = responseData?.data?.assets || null;
    },
  },
});
