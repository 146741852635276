// useApiRequest.js (composable)
import { ref } from 'vue';

export function useApiRequest() {
  const isLoading = ref(false);
  const error = ref(null);

  async function handleRequest(apiCall, ...args) {
    isLoading.value = true;
    try {
      const response = await apiCall(...args);
      return response?.data;
    } catch (err) {
      error.value = err.message;
      throw err;
    } finally {
      isLoading.value = false;
    }
  }

  function clearError() {
    error.value = null;
  }

  return {
    isLoading,
    error,
    handleRequest,
    clearError,
  };
}
