import { defineStore } from 'pinia';
import api from '@/services/api.js';

export const useCohortReportStore = defineStore({
  id: 'CohortReportStore',
  state: () => ({
    cohortReports: [],
    totalCount: 0,
  }),

  getters: {
    getCohortReports: (state) => state.cohortReports,
    totalResults: (state) => state.totalCount,
  },
  actions: {
    async fetchCohortReports(paramsObj) {
      const responseData = await api.getResourceList('reports', paramsObj);
      this.cohortReports = responseData?.data || [];
      this.totalCount = responseData?.total_count || 0;
    },
    async generateCohortReports(data) {
      await api.addResource('reports', data);
    },
  },
});
