import { createApp } from 'vue';
import { createPinia } from 'pinia';
import App from './App.vue';
import router from '@/router/index';
import '@/assets/main.css';
import BaseCard from '@/components/ui/BaseCard.vue';
import BaseButton from '@/components/ui/BaseButton.vue';
import BaseInput from '@/components/ui/BaseInput.vue';
import BaseDialog from '@/components/ui/BaseDialog.vue';
import BaseInfoDialog from '@/components/ui/BaseInfoDialog.vue';
import BaseSpinner from '@/components/ui/BaseSpinner.vue';
import BaseMultiSelect from '@/components/ui/BaseMultiSelect.vue';
import FontAwesomeIcon from '@/utils/fa_icons';
const pinia = createPinia();
const app = createApp(App);

app
  .use(pinia)
  .use(router)
  .component('font-awesome-icon', FontAwesomeIcon)
  .component('base-card', BaseCard)
  .component('base-button', BaseButton)
  .component('base-input', BaseInput)
  .component('base-dialog', BaseDialog)
  .component('base-info-dialog', BaseInfoDialog)
  .component('base-spinner', BaseSpinner)
  .component('base-multiselect', BaseMultiSelect);

app.mount('#app');
