const moment = require('moment');

export const validateFn = (param) => {
  var re = new RegExp(param.pattern, 'g');

  if (re.test(param.value)) {
    if (param.max && param.min) {
      if (param.value.length >= param.min && param.value.length <= param.max) {
        return;
      } else {
        return `Allowed Char: ${param.min} - ${param.max}`;
      }
    }

    if (param.max) {
      if (param.value.length <= param.max) {
        return;
      } else {
        return `Allowed Max Char: ${param.max}`;
      }
    }

    if (param.min) {
      if (param.value.length >= param.min) {
        return;
      } else {
        return `Allowed Min Char: ${param.min}`;
      }
    }

    return;
  } else {
    return param.errMsg;
  }
};

// alphanumeric only
export const validateAlphanumeric = (value) => {
  if (/^[A-Za-z0-9]+$/.test(value)) {
    return;
  } else {
    return 'Accept alphanumeric only';
  }
};

// dont allow empty or tabs
export const validateIfEmpty = (value) => {
  if (value?.trim().length > 0) {
    return;
  } else {
    return 'Empty value is not allowed.';
  }
};

// integers only
export const validateNumber = (value) => {
  if (/^(?!e)[0-9]+$/.test(value)) {
    return;
  } else {
    return 'Accept Integers only';
  }
};

//check valid date
export const validateDate = (value) => {
  if (moment(value, 'YYYY-MM-DD', true).isValid()) {
    return;
  } else {
    return ' Invalid Date formate';
  }
};

//check duplicate string
export const hasDuplicates = (collection) => {
  const duplicateItems = {};
  for (const item of collection) {
    if (duplicateItems[item]) {
      return `: "${item}" is Duplicate.`; // Found a duplicate
    }
    duplicateItems[item] = true;
  }
  return;
};
