<template>
    <main>
        <h1>404</h1>
        <div class="notFound">
            <p>The page you are attempting to reach is currently not available. This may be because the page does not exist
                or has been moved.</p>
            <base-button to="/cohorts" mode='primaryBtn' link>Back Home</base-button>

        </div>
    </main>
</template>
<style scoped>
.notFound {
    text-align: center;
    padding: 25px;
    font-size: 18px;
    max-width: 700px;
    margin: auto;
    line-height: 2;
}

h1 {
    color: #2d1967 !important;
    text-align: center;
    font-size: 200px;
    margin: 40px 0 -30px 0;
}

a.primaryBtn {
    margin-top: 30px;
}
</style>
