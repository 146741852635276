<template>
    <main class="greenBg">
        <section class="headSection">
            <div class="headTitle">
                <h2>Portfolio</h2>
            </div>
        </section>
        <section>
            <base-card>
                <div>
                    <span><font-awesome-icon icon="fa-wallet" /></span>
                    <h3>Portfolio feature coming soon!</h3>
                </div>
            </base-card>
        </section>
    </main>
</template>
<style scoped>
.card {
    justify-content: center;
    padding: 100px;
}

.card>div {
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

span {
    width: 100px;
    height: 100px;
    background: #ddd;
    display: inline-flex;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    font-size: 36px;
    color: #666;
}
</style>
