<template>
  <div class="card">
    <slot></slot>
  </div>
</template>

<style scoped>
.card {
  background-color: #fff;
  box-shadow: 0 1px 14px 0 rgb(0 0 0 / 7%);
  border-radius: 5px;
}
</style>
