<template>
  <tr>
    <td v-for="item in colItem" :key="item">
      <a v-if="item === colItem.filename" :href="fileLink" target='_blank'>{{ item }}</a>
      <template v-else>
        {{ item }}
      </template>
    </td>
  </tr>
</template>
<script>
import moment from 'moment';
export default {
  props: [
    'report',
  ],
  computed: {
    colItem() {
      const item = {
        created_at: this.getDate(this.report.created_at),
        report_type: this.report.report_type,
        filename: this.report.filename,
      };
      return item;
    },
    fileLink() {
      return this.report.url;
    }
  },
  methods: {
    getDate(timestamp) {
      return moment(timestamp).format('YYYY-MM-DD');
    }
  }
};
</script>
