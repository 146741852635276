<template>
  <router-link v-if="link && to" :to="to" :class="mode">
    <button>
      <slot></slot>
    </button>
  </router-link>

  <button v-else-if="!link" :class="mode">
    <slot></slot>
  </button>

  <router-link v-else :to="to" :class="mode">
    <slot></slot>
  </router-link>
</template>
<script>
export default {
  props: {
    mode: {
      type: String,
      required: false,
      default: null
    },
    link: {
      type: Boolean,
      required: false,
      default: false,
    },
    to: {
      type: String,
      required: false,
      default: '/',
    },
  },
};
</script>
<style scoped>
button {
  border: 0;
  cursor: pointer;
  padding: 14px 30px !important;
  text-transform: capitalize;
  line-height: 16px;
  font-size: 13px;
  font-weight: 500;
  border-radius: 5px;
  white-space: nowrap;
  -webkit-transition: .3s;
  transition: .3s;
  font-family: nunito, sans-serif;
}

button.primaryBtn,
a.primaryBtn button {
  background-color: var(--primary-blue);
  color: #fff;
}

button.primaryBtn:hover,
a.primaryBtn button:hover {
  box-shadow: 0 3px 11px var(--primary-blue);
}

button.danger:hover {
  box-shadow: 0 3px 11px var(--red);
}

.primaryBtn.iconBtn:hover,
a.primaryBtn.iconBtn button:hover {
  box-shadow: none;

}

button.iconBtn,
a.iconBtn button {
  padding: 5px 3px !important;
  width: 20px;
  background: none;
  color: var(--dark-fontIcon-color);
}

button.iconBtn.primaryBtn,
.iconBtn.primaryBtn button {
  color: var(--primary-blue) !important;
}

a {
  text-decoration: none;
  color: #fff;
  /* margin: 5px 7px; */
  display: inline-block;
}

button.danger {
  background-color: var(--red);
  color: #fff;
}

.whiteBtn {
  background-color: #fff;
  background: #fff;
  color: #101038;
  border-radius: 5px;
  box-shadow: 0 3px 11px rgba(0, 0, 0, .17);
}

.whiteBtn>button {
  background: #fff;
}

.whiteBtn>button:hover {
  background: var(--primary-blue);
  color: #fff;
}

a.iconBtn.whiteBtn>button {
  padding: 12px !important;
  width: auto;
}

.secondaryBtn {
  border: 1px solid #ced4da;
}

.secondaryBtn:hover {
  background-color: var(--secondary-text-color);
  color: #fff;
}

button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
</style>
