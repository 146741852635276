/* eslint-disable */

export const API_URL = process.env.VUE_APP_API_URL;
export const THIRD_PARTY_LOGOUT_URL =
  process.env.VUE_APP_THIRD_PARTY_LOGOUT_URL;
export const THIRD_PARTY_LOGIN_URL = process.env.VUE_APP_THIRD_PARTY_LOGIN_URL;
export const PAIR_APP_LINK = process.env.VUE_APP_PAIR_APP_LINK;
export const APP_INSTANCE_URL_MAPPER = {
  de: process.env.VUE_APP_DE_INSTANCE_URL,
  at: process.env.VUE_APP_AT_INSTANCE_URL,
  ch: process.env.VUE_APP_CH_INSTANCE_URL,
  nh: process.env.VUE_APP_NL_INSTANCE_URL,
};
