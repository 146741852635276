import { defineStore } from 'pinia';
import api from '@/services/api.js';

export const useCohortInvoiceStore = defineStore({
  id: 'CohortInvoiceStore',
  state: () => ({
    cohortInvoices: [],
    totalCount: 0,
  }),

  getters: {
    getCohortInvoices: (state) => state.cohortInvoices,
    totalResults: (state) => state.totalCount,
  },
  actions: {
    async fetchCohortInvoices(paramsObj) {
      const responseData = await api.getResourceList('asset_invoices', paramsObj);
      this.cohortInvoices = responseData?.data || [];
      this.totalCount = responseData?.total_count || 0;
    },

    async retrieveCohortInvoices(data) {
      await api.addResource('asset_invoices/bulk_create', data);
    },
  },
});
