<template>
  <div>
    <base-card>
      <reports-form @submit-form="generateReports"></reports-form>
    </base-card>
    <base-card>
      <table>
        <tr>
          <th v-for="col in tableCol" :key="col">{{ col }}</th>
        </tr>
        <report-item v-for="report in reports" :key="report.filename" :report="report"></report-item>
      </table>
    </base-card>
    <pagination-item :total="totalResults" :per-page="perPage" :current-page="currentPage"
      @setPage="onPageChange"></pagination-item>
  </div>
</template>
<script>
import { useReportStore } from '@/store/reports/index.js';
import ReportItem from '@/components/report/ReportItem.vue';
import { useApiRequest } from '@/composables/useApiRequest';
import { useApiResponseStore } from '@/store/apiResponse';
import ReportsForm from '@/components/report/ReportsForm.vue';
import PaginationItem from '@/components/pagination/PaginationItem.vue';

export default {
  components: {
    ReportItem,
    ReportsForm,
    PaginationItem
  },
  data() {
    return {
      currentPage: 1,
      perPage: 25,
      tableCol: ['Created Date', 'Report Type', 'File Name'],
    };
  },
  created() {
    this.storeReport = useReportStore();
    this.apiRequest = useApiRequest();
    this.storeApiResponse = useApiResponseStore();
    this.loadCohortsReports();
  },
  computed: {
    isLoading() {
      return this.apiRequest.isLoading.value;
    },
    reports() {
      return this.storeReport.getReports;
    },
    hasCohortsReports() {
      return this.reports && (this.reports.length > 0);
    },
    totalResults() {
      return this.storeReport.totalReportsResults;
    }
  },
  methods: {
    async loadCohortsReports() {
      const paramsObj = {
        report_type: 'all_cohorts',
        sort_field: 'created_at',
        sort_order: 'desc',
        page: this.currentPage,
        per_page: this.perPage
      };
      await this.apiRequest.handleRequest(this.storeReport.fetchReports, paramsObj);
    },
    async generateReports(data) {
      this.apiRequest.clearError();
      try {
        await this.apiRequest.handleRequest(this.storeReport.generateReports, data);
        this.loadCohortsReports();
        this.storeApiResponse.setSuccessMsg('A new Report is Generated.');
      } catch (err) {
        this.storeApiResponse.setError(err.message);
      }
    },
    onPageChange(page) {
      this.currentPage = page;
      this.loadCohortsReports();
    },
  }
};
</script>
