<template>
  <base-card>
    <form @submit.prevent="submitForm" class="form">
      <div>
        <div class="form-control disabled" v-if="seller">
          <label for="slug">Slug</label>
          <span>{{ slug }}</span>
        </div>
        <base-input v-else v-model="slug" label="Slug" type="text" id="slug" :error="error?.slug"
          :validate-fn="validateSlug" />
        <base-input v-model="name" label="Name" type="text" id="name" :error="error?.name" :validate-fn="validateName" />
      </div>
      <div>
        <base-input v-model="iban" label="IBAN" type="text" id="iban" :error="error?.iban"
          :validate-fn="validateAlphanumeric" />
        <base-input v-model="bic" label="BIC" type="text" id="bic" :error="error?.bic"
          :validate-fn="validateAlphanumeric" />
      </div>
      <div class="fullWidth">
        <base-input v-model="address" label="Address" type="text" id="address" :error="error?.address"
          :validate-fn="validateAddress" />
      </div>
      <div>
        <base-input v-model="datev_creditor_id" label="Datev Creditor Id" id="datev_creditor_id" type="number"
          :error="error?.datev_creditor_id" :validate-fn="validateNumber" />
        <base-input v-model="datev_debtor_id" label="Datev Debtor Id" id="datev_debtor_id" type="number"
          :error="error?.datev_debtor_id" :validate-fn="validateNumber" />
      </div>
      <div class="fullWidth">
        <base-input textarea v-model="company_slugs" label="Company Slugs"
          placeholder="Type Slugs separated by space / newline / comma" id="company_slugs"
          :error="error?.company_slugs" />
      </div>
      <div class="fullWidth">
        <div class="form-control">
          <label for="purchased_type_component" class="selectLabel"> Purchased Claim Types</label>
          <select v-model="purchased_type_component" id="purchased_type_component">
            <option v-for="item in purchasedTypeComponents" :key="item">{{ item }}</option>
          </select>
        </div>
      </div>
      <div class="footerBtn"><base-button mode="primaryBtn" :disabled="isFormInvalid">Submit</base-button></div>
    </form>
  </base-card>
</template>

<script>
import { useSellerStore } from '@/store/sellers/index.js';
import { useApiResponseStore } from '@/store/apiResponse';
import { useApiRequest } from '@/composables/useApiRequest';
import { validateSlug, validateName, validateAddress } from '@/utils/validators/sellerValidator';
import { validateAlphanumeric, validateNumber, validateIfEmpty } from '@/utils/validators/commonValidator';

export default {
  props: ['seller', 'error'],
  data() {
    const { slug, name, iban, bic, address, datev_creditor_id, datev_debtor_id, purchased_type_component } = this.seller || {};
    return {
      validationErr: {},
      slug: slug || '',
      name: name || '',
      iban: iban || '',
      bic: bic || '', address: address || '',
      datev_creditor_id: datev_creditor_id || '',
      datev_debtor_id: datev_debtor_id || '',
      company_slugs: this.seller ? this.seller.company_slugs.join(' ') : '',
      purchased_type_component: purchased_type_component || ''
    };
  },
  created() {
    this.storeSeller = useSellerStore();
    this.apiRequest = useApiRequest();
    this.storeApiResponse = useApiResponseStore();
    this.loadPurchasedTypeComponents();
  },
  emits: ['submit-form'],
  computed: {
    purchasedTypeComponents() {
      return this.storeSeller.getPurchasedTypeComponents;
    },
    isFormInvalid() {
      return validateSlug(this.slug) || validateName(this.name) || validateAddress(this.address) || validateAlphanumeric(this.bic) || validateAlphanumeric(this.iban) || validateNumber(this.datev_creditor_id) || validateNumber(this.datev_debtor_id) || validateIfEmpty(this.company_slugs) || validateIfEmpty(this.purchased_type_component);
    }
  },
  methods: {
    validateSlug, validateName, validateAddress, validateAlphanumeric, validateNumber, validateIfEmpty,
    submitForm() {
      const formData = {
        slug: this.slug,
        name: this.name,
        iban: this.iban,
        bic: this.bic,
        address: this.address,
        datev_creditor_id: this.datev_creditor_id,
        datev_debtor_id: this.datev_debtor_id,
        company_slugs: this.company_slugs.replace(/(,|\n|\t|\r)+/g, ' ').trim().split(' ').filter(i => i),
        purchased_type_component: this.purchased_type_component
      };
      this.$emit('submit-form', formData);
    },

    async loadPurchasedTypeComponents() {
      const paramObj = {
        sellers: {
          columns: ['purchased_type_component']
        }
      };
      await this.apiRequest.handleRequest(this.storeSeller.fetchPurchasedTypeComponents, paramObj);
    },
  }
};
</script>
<style scoped src="@/assets/form.css">
</style>
