<template>
  <main class="greenBg">
    <section class="headSection">
      <div class="headTitle">
        <h2>Sellers</h2>
        <base-button to="/sellers/new" mode='whiteBtn' link>New Seller</base-button>
      </div>
    </section>

    <section>
      <base-card class="tableCard">
        <div v-if="isLoading">
          <base-spinner></base-spinner>
        </div>
        <table v-else-if="hasSeller">
          <tr>
            <th v-for="col in tableCol" :key="col">{{ col }}</th>
          </tr>
          <seller-item v-for="seller in sellers" :key="seller.slug" :seller="seller"
            @delete-seller="deleteSeller"></seller-item>
        </table>
        <div v-else>
          <p class="centerAlign">No Seller Found</p>
        </div>
      </base-card>
    </section>
  </main>
  <base-dialog :show="showDeleteDialog && deleteSlug" title="Delete Seller" @cancel="closeDialog" @confirm="handleDelete">
    <p>Do you really want to Delete <b>Slug:{{ deleteSlug }}</b> ?</p>
  </base-dialog>
</template>

<script>
import { useSellerStore } from '@/store/sellers/index.js';
import SellerItem from '@/components/seller/SellerItem.vue';
import { useApiRequest } from '@/composables/useApiRequest';
import { useApiResponseStore } from '@/store/apiResponse';

export default {
  components: {
    SellerItem,
  },
  data() {
    return {
      tableCol: ['Slug', 'Name', 'IBAN', 'BIC', 'Address', 'Datev Creditor Id', 'Datev Debtor Id', 'Company Slugs', 'Purchased Claim Types', 'Actions'],
      showDeleteDialog: false,
      deleteSlug: '',
    };
  },
  created() {
    this.storeSeller = useSellerStore();
    this.apiRequest = useApiRequest();
    this.storeApiResponse = useApiResponseStore();
    this.loadSellers();
  },
  computed: {
    isLoading() {
      return this.apiRequest.isLoading.value;
    },
    sellers() {
      return this.storeSeller.getSellers;
    },
    hasSeller() {
      return this.sellers && (this.sellers.length > 0);
    },
  },
  methods: {
    async loadSellers() {
      await this.apiRequest.handleRequest(this.storeSeller.fetchSellers);
    },
    deleteSeller(slug) {
      this.showDeleteDialog = true;
      this.deleteSlug = slug;
    },
    closeDialog() {
      this.showDeleteDialog = false;
    },
    async handleDelete() {
      this.apiRequest.clearError();
      this.showDeleteDialog = false;
      try {
        await this.apiRequest.handleRequest(this.storeSeller.deleteSeller, this.deleteSlug);
        this.storeApiResponse.setSuccessMsg(`Seller : ${this.deleteSlug} is deleted`);
        this.loadSellers();
      } catch (err) {
        this.storeApiResponse.setError(err.message);
      }
    },
  }
};
</script>
