import { defineStore } from 'pinia';

export const useApiResponseStore = defineStore('apiResponseStore', {
  id: 'apiResponseStore',
  state: () => ({
    error: null,
    key: null,
    successMsg: null,
    redirectPath: null,
    title: null,
  }),

  actions: {
    setErrorMsg(errMsg, title = 'An error Occured!') {
      this.error = errMsg;
      this.key = Date.now();
      this.title = title;
    },
    setSuccessMsg(successMsg, redirectPath = null) {
      this.successMsg = successMsg;
      this.redirectPath = redirectPath;
    },
    clearError() {
      this.error = null;
      this.title = null;
    },
    clearSuccessMsg() {
      this.successMsg = null;
      this.redirectPath = null;
    },
    setError(error) {
      if (error.response) {
        if (error.response.status === 401 || error.response.status === 403) {
          localStorage.removeItem('liquandumCSRFtoken');
          localStorage.removeItem('liquandumUser');
          location.href = `${location.origin}/login`;
        } else if (error.response.status === 500) {
          location.href = `${location.origin}/500error`;
        } else if (error.response.status === 404) {
          location.href = `${location.origin}/404error`;
        } else if (error.response.status === 422) {
          this.setErrorMsg(error.response.data.errors?.message);
        }
      }
    },
  },
});
