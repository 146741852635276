/* eslint-disable no-sequences */
/* eslint-disable no-unused-expressions */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';

import {
  faBars,
  faWindowClose,
  faTrashAlt,
  faUserCircle,
  faBell,
  faFileCsv,
  faWallet,
  faTrash,
  faMoneyBill,
  faBuildingColumns,
  faCaretRight,
  faAngleDoubleDown,
  faFileImport,
  faTableCellsLarge,
  faMagnifyingGlass,
  faFilter,
  faPhone,
  faUser,
  faFlag,
  faFilePdf,
  faTrashCan,
  faClipboardQuestion,
  faClock,
  faFlagCheckered,
  faPenToSquare,
  faEnvelopesBulk,
  faArrowRotateLeft,
  faCirclePlus,
  faHand,
  faHourglass,
  faXmark,
  faCircleXmark,
  faBriefcase,
  faRotate,
  faPersonChalkboard,
  faHandPointer,
  faArrowLeft,
  faArrowRight,
  faArrowRightToBracket,
  faArrowRightFromBracket,
  faClapperboard,
  faUserGraduate,
  faPersonCane,
  faPeoplePulling,
  faPeopleRoof,
  faCircleCheck,
  faMoneyBillTrendUp,
  faStairs,
  faPlus,
  faTimes,
  faFolder,
  faFolderOpen,
  faUserCheck,
  faEye,
  faSpinner,
  faEnvelope,
  faImage,
  faForwardStep,
  faBackwardStep,
  faUserLock,
  faPaperPlane,
  faCircleRadiation,
} from '@fortawesome/free-solid-svg-icons';

library.add(faBars),
library.add(faWindowClose),
library.add(faTrashAlt),
library.add(faUserCircle),
library.add(faBell),
library.add(faFileCsv),
library.add(faWallet),
library.add(faBuildingColumns),
library.add(faMoneyBill),
library.add(faTableCellsLarge);
library.add(faTrash);
library.add(faFilter);
library.add(faAngleDoubleDown);
library.add(faFileImport);
library.add(faCaretRight);

library.add(faPhone);
library.add(faCirclePlus);
library.add(faEnvelopesBulk);
library.add(faUser);
library.add(faFlag);
library.add(faFilePdf);
library.add(faArrowLeft);
library.add(faArrowRight);
library.add(faCircleCheck);
library.add(faCircleXmark);
library.add(faTrashCan);
library.add(faClipboardQuestion);
library.add(faClock);
library.add(faRotate);
library.add(faFlagCheckered);
library.add(faPenToSquare);
library.add(faArrowRotateLeft);
library.add(faHand);
library.add(faHourglass);
library.add(faXmark);
library.add(faBriefcase);
library.add(faPersonChalkboard);
library.add(faHandPointer);
library.add(faArrowRightToBracket);
library.add(faArrowRightFromBracket);
library.add(faClapperboard);
library.add(faUserGraduate);
library.add(faPersonCane);
library.add(faPeoplePulling);
library.add(faPeopleRoof);
library.add(faPlus);
library.add(faTimes);
library.add(faEnvelope);
library.add(faMoneyBillTrendUp);
library.add(faStairs);
library.add(faFolder);
library.add(faFolderOpen);
library.add(faUserCheck);
library.add(faEye);
library.add(faSpinner);
library.add(faImage);
library.add(faForwardStep);
library.add(faBackwardStep);
library.add(faUserLock);
library.add(faPaperPlane);
library.add(faCircleRadiation);
library.add(faMagnifyingGlass);

export default FontAwesomeIcon;
