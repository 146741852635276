<template>
  <cohort-report-form @submit-form="generateCohortReport"></cohort-report-form>

  <template v-if="hasReports">
    <base-card class="tableCard">
      <table>
        <tr>
          <th v-for="col in tableCol" :key="col">{{ col }}</th>
        </tr>
        <cohort-report-item v-for="report in reports" :key="report.filename" :report="report"></cohort-report-item>
      </table>
    </base-card>
    <pagination-item :total="totalResults" :per-page="perPage" :current-page="currentPage"
      @setPage="onPageChange"></pagination-item>

  </template>
  <base-card v-else>
    <p class="centerAlign">No Cohort - Handedover Reports Found. </p>
  </base-card>
</template>
<script>

import { useCohortReportStore } from '@/store/cohorts/reports.js';
import { useApiResponseStore } from '@/store/apiResponse';
import { useApiRequest } from '@/composables/useApiRequest';
import CohortReportForm from '@/components/cohort/CohortReportForm.vue';
import CohortReportItem from '@/components/cohort/CohortReportItem.vue';
import PaginationItem from '@/components/pagination/PaginationItem.vue';

export default {
  data() {
    return {
      currentPage: 1,
      perPage: 25,
      tableCol: ['Created Date', 'Report Type', 'File Name']
    };
  },
  props: ['slug'],
  components: { CohortReportForm, CohortReportItem, PaginationItem },
  created() {
    this.storeCohortReport = useCohortReportStore();
    this.apiRequest = useApiRequest();
    this.storeApiResponse = useApiResponseStore();
    this.loadCohortReports();
  },
  computed: {
    isLoading() {
      return this.apiRequest.isLoading.value;
    },
    hasReports() {
      return this.storeCohortReport.getCohortReports?.length > 0;
    },
    reports() {
      return this.storeCohortReport.getCohortReports;
    },
    totalResults() {
      return this.storeCohortReport.totalCount;
    },
  },
  methods: {
    async loadCohortReports() {
      const paramObj = {
        resource: 'cohorts',
        resource_slug: this.slug,
        sort_field: 'created_at',
        sort_order: 'desc',
        page: this.currentPage,
        per_page: this.perPage
      };
      await this.apiRequest.handleRequest(this.storeCohortReport.fetchCohortReports, paramObj);
    },
    async generateCohortReport(formData) {
      const data = {
        ...formData, resource: 'cohorts',
        resource_slug: this.slug
      };
      this.apiRequest.clearError();
      try {
        await this.apiRequest.handleRequest(this.storeCohortReport.generateCohortReports, data);
        this.loadCohortReports();
        this.storeApiResponse.setSuccessMsg('A new Report is Generated.');
      } catch (err) {
        this.storeApiResponse.setError(err.message);
      }
    },
    onPageChange(page) {
      this.currentPage = page;
      this.loadCohortReports();
    },
  }
};
</script>
